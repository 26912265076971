import React from 'react';
import jwt_decode from "jwt-decode";


function decodedToken() {
    let token = localStorage.getItem("token");
  
    let decodedToken = token===null?{}:jwt_decode(token);   
  
    return decodedToken
}

export default decodedToken
