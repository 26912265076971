import TablePayment from "../components/TablePayment.js";



const PaymentPage=()=>{

   



   
    return(
        <>
        

            
     <div style={{width: "83%",overflow: "scroll",marginLeft:"17%" }}> 

        
        <TablePayment/>
        </div>

        </>
    )
}
export default PaymentPage;