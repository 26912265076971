import React, { useState } from "react";
import "../css/loginregister.css";
import Bgimg from "../images/bgimg.svg";
import "../css/Navbar.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import decodedToken from "../components/decodedToken";
import { useHistory } from "react-router-dom";
import { loginAction } from "../reducer";
const emailPattern = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const LoginRegister = () => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errMsg, setErrMsg] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, [e.target.name]: value });
    setErrMsg(false);
  };

  const postBodyConstructor = (loginData) => {
    let fieldArray = [];
    for (let key in loginData) {
      let field = {};
      field["fieldName"] = key;
      field["fieldValue"] = loginData[key];
      fieldArray.push(field);
    }
    return fieldArray;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!emailPattern.test(user.email)) {
      setErrMsg("Email is not valid");
      setError(true);
      return;
    }
    if (user.password === "") {
      setErrMsg("Password should not be empty");
      setError(true);
      return;
    }
    submitDetails();
  };

  const submitDetails = () => {
    // console.log("kjasndjasd")
    let loginDetails = postBodyConstructor(user);
    setError(false);
    // url: `http://localhost:1998/employee`,
    //url:`https://server.teachnook.com`
    axios({
      method: "post",
      url: `https://server.teachnook.com/employee`,
      data: loginDetails,
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          let decodeToken = decodedToken();
          dispatch(loginAction(decodeToken.tabs));
          history.push(`/${decodeToken.tabs[0]}`);
        } else if (res.status === 202) {
          setErrMsg(res.data);
          setError(true);
        } else if (res.status === 422) {
          setErrMsg(res.data);
          setError(true);
        }
      })
      .catch((err) => { });
  };

  return (
    <>
      <div className="login-register">
        <div className="login-inner">
          <div className="login-form">
            <form method="POST" className="input-field">
              <h1
                style={{
                  textAlign: "center",
                  color: "#071e40",
                  fontSize: "30px",
                }}
              >
                Login
              </h1>
              <div>
                {" "}
                <input
                  type="text"
                  placeholder="Email"
                  className="inputtext"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                />
              </div>
              {/* {error && !emailPattern.test(user.email)?<p  style={{color : "red"}}>Invalid Email</p>:null} */}
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  className="inputtext"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                />
                {error ? (
                  <p style={{ color: "red", textAlign: "left" }}>{errMsg}</p>
                ) : null}
                <a href="/forgot-password">
                  <p style={{ textAlign: "right" }}>Forgot password?</p>
                </a>
              </div>

              <button
                type="submit"
                onClick={handleSubmit}
                className="login-btn"
              >
                Login
              </button>
            </form>
          </div>
          <div className="login-img">
            <img src={Bgimg} style={{ width: "69%" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginRegister;
