import axios from "axios";
import React, { useEffect } from "react";
import { getAPI } from "./api";

function DeleteItem(props) {
  let catName = props.catName;
  let IID = props.IID;
  let API=getAPI()
  useEffect((
  )=>{
    
API({
  url:`deleteItem/${catName}/${IID}`,
  method:"delete"
})
.then((res)=>{
if(res.status ===200 && !res.data){
  alert("IID and catname is reuired")
}
else if(res.status === 500 ){
  alert("Something went wrong while deleting")
}
else if(res.status === 200 && res.data ==="Deleted"){
  props.getData()
  alert("Deleted")
}
})
.catch((e)=>{

})
  },[])

  return <div></div>;
}

export default DeleteItem;
