import React,{useEffect, useState} from 'react';
import './global.css'
import { getAPI } from "../api";
import decodedToken from "../decodedToken";
import "./domain.css";
import AllDomain from './Domain'
export default function Domain()
{
      let API =getAPI()
     let{role}=decodedToken()
     const initial={name: "", description: "" }
     const [formData, setFormData] = useState(initial)
    const [currentState, setCurrentState] = useState("Domain")
    const [selectedDepartment, setSelectedDepartment] = useState({departmentId:"",departmentName:""})
    const[selectedDomain,setSelectedDomain]=useState({domainId:"",domainName:""})
    const [image, setImage] = useState();
    const [pages, setPages] = useState([]);
  const [isOpen, setOpen] = useState("");
  
   const handleCheck = (obj) => {
    for (let key in obj) {
      if (obj[key] === "") {
        alert(`${key} shouldn't be empty`);
        return false;
      }
    }
    return true;
  };
     const createDomain = (formData,setFormData) => {
       if (handleCheck(formData)) {
         if (currentState === "Domain") {
               API({
      url: 'domain/createdomain',
      method:"post",
      data: formData,
    })
      .then((response) =>
      {
        if(response.data=="field should not be empty/null"){alert("field should not be empty/null")}
        else if (response.data == "Course already exists! Try different name") { alert("Course already exists! Try different name") }
 
       
        else if (response.data == "Failed") {
         alert("course could not be created");
       }
         else if (response.status===201) {
           alert("Successfully created");
           document.getElementById("form").reset();
           getData()
           setFormData()
         }
             setOpen("");
      })
      .catch((err) => {
        console.log(err);
      });
         }
     else   if (currentState === "Department") {
               API({
      url: "department/createdepartment",
      method:"post",
      data: {...formData,...selectedDomain},
    })
      .then((response) =>
      {
        if(response.data=="field should not be empty/null"){alert("field should not be empty/null")}
        else if (response.data == "Department already exists! Try different name") { alert("Course already exists! Try different name") }
 
       
        else if (response.data == "Failed") {
         alert("course could not be created");
       }
         else if (response.status===201) {
           alert("Successfully created");
           getData()
           setFormData()

           document.getElementById("form").reset();
         }
             setOpen("");
      })
      .catch((err) => {
        console.log(err);
      });
         }
     else   if (currentState === "Course") {
               API({
      url: "course/createcourse",
      method:"post",
      data: {...formData,...selectedDepartment},
    })
      .then((response) =>
      {
       if(response.data=="field should not be empty/null"){alert("field should not be empty/null")}
       else if (response.data == "Course already exists! Try different name") { alert("Course already exists! Try different name") }

      
       else if (response.data == "Failed") {
        alert("course could not be created");
      }
        else if (response.status===201) {
          alert("Successfully created");
           getData()
           setFormData()
          document.getElementById("form").reset();
        }
            setOpen("");
      })
      .catch((err) => {
        console.log(err);
      });
         }
       }
       else {
         alert("fill all details")
       }
    };
    const getData = () =>
    {
        if (currentState === "Domain") {
                        API.get("domain/getalldomain"
    )
                          .then((response) =>
                          {
        setPages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
        }
        else if (currentState === "Department"&&selectedDomain.domainId) {
      API.get(`department/getalldepartments/${selectedDomain.domainId}`
    )
      .then((response) => {
        setPages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
        }
         else if (currentState === "Course"&&selectedDepartment.departmentId&&selectedDomain.domainId) {
        API.get(`course/getallcourses/${selectedDepartment.departmentId}/${selectedDomain.domainId}`)
      .then((response) => {
        setPages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
        }
    }
    const handleEdit = (formData) =>
    {

      if (currentState === "Domain") {
        API({
url: 'domain/modifydomain',
method:"put",
data: formData,
})
.then((response) =>
{
 if(response.data=="field should not be empty/null"){alert("field should not be empty/null")}

else if(response.status===200 && !response.data){alert("No modification made")}
 
 else if (response.data == "domain could not be modified") {
   alert("domain could not be modified");
 }else if (response.data == "domian successfully modified") {
  alert("domian successfully modified");
}
     setOpen("");
})
.catch((err) => {
 console.log(err);
});
  }
else   if (currentState === "Department") {
        API({
url: "department/modifydepartment",
method:"put",
data: {...formData,...selectedDomain},
})
.then((response) =>
{
  if(response.data=="field should not be empty/null"){alert("field should not be empty/null")}

else if(response.status===200 && !response.data){alert("No modification made")}
 
  else if (response.data == "department could not be modified") {
    alert("department could not be modified");
  }else if (response.data == "department successfully modified") {
   alert("department successfully modified");
 }
      setOpen("");
 })
.catch((err) => {
 console.log(err);
});
  }
else   if (currentState === "Course") {
        API({
url: "course/modifycourse",
method:"put",
data: formData,
})
.then((response) =>
{
 if (response.data == "field should not be empty/null") { alert("field should not be empty/null") }
else if(response.status===200 && !response.data){alert("No modification made")}

else if (response.data == "course could not be modified") {
  alert("course could not be modified");
}
 else if (response.data == "course successfully modified") {
   alert(" course successfully modified");
 }
     setOpen("");
})
.catch((err) => {
 console.log(err);
});
  }
    
}
      useEffect(() => {
    getData()
  }, [currentState]);
  return (
    <>
          <AllDomain initial={initial} formData={formData} setFormData={setFormData} handleEdit={handleEdit} selectedDomain={selectedDomain} setSelectedDomain={setSelectedDomain} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} currentState={currentState} setCurrentState={setCurrentState} pages={pages} getData={getData} image={image} isOpen={isOpen} setOpen={setOpen} handleCreate={createDomain} role={role} setImage={setImage} />
    </>
  );
}
