




export const loginAction = (e)=>{
    return {
        type:'LOGIN',
        tabs:e
    }
}

// export const logoutAction  = (e)=>{
//     return{
//         type:'LOGOUT'
//     }
// }