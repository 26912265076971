import { useState } from "react";

const useCounter = () => {
  const [ orderCount, setOrderCount ] = useState(1);

  const incrementCount = (e) => {
    e.preventDefault();

    setOrderCount(orderCount + 1);
  };

  const decrementCount = (e) => {
    e.preventDefault();
    if (orderCount > 1) {
      setOrderCount(orderCount - 1);
    }
  };

  return { orderCount, incrementCount, decrementCount };
};
export default useCounter;
