import TableAddemploye from "../components/TableAddemploye.js"
import React,{ useState , useEffect} from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import CloseIcon from '@mui/icons-material/Close';

// Components

import {EditEmployee} from "../components/addemp/addemp"

const AddempoPage=()=>{
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = (e) => {
        const back = document.getElementById("modalBackDrop")
        if (e==="Close" || e.target === back) {
            setOpen(false);
        }
      };
  
    return(
        <>
        
         

                     
     <div style={{ width: "83%" ,marginLeft:"17%", overflowY: "scroll"}}> 
     {/* , display:"flex", flexDirection:"column",justifyContent:"flex-start" */}
      {/* <button style={{backgroundColor:"#031251", color:"white", border:"none", padding:"1% 1.5%", borderRadius:"10px", margin:"5% 0% 2% 2%"}}>Add Employee</button> */}
      <div className="PopUpNews">
    <div>
      <Button style={{backgroundColor:"#031251", color:"white", border:"none", padding:"1% 1.5%", borderRadius:"10px", margin:"5% 0% 2% 2%"}} variant="outlined" onClick={handleClickOpen}>
        Add Employee
      </Button>
      {open?
      <div
        id="modalBackDrop"
        style={{
        height: '100%',
        width: '100%',    position: "fixed",
        zIndex: "111111111",
        padding: "2%",
        top: "0%",
        backgroundColor:" #00000096",
                    }}
                    onClick={(e)=>handleClose(e)}
                >


        <EditEmployee handleClose={(e)=>handleClose(e)} />
      </div>:null}
    </div>

      </div>
     <TableAddemploye/>
     </div>
         

    

        </>
    )
}
export default AddempoPage;