import React, { useEffect } from 'react';

import { getAPI } from '../api';


function StatusChange(props) {
const API = getAPI()
    let catName=props.catName
    let IID = props.IID.IID
    let status=props.IID.status
    let value=props.IID.value
    let send={"type":value}
 
     useEffect(() => {   props.IID.value?
         API({
             method: "POST",
             url: `/statusChange/${catName}/${IID}/${status}`,
             data:send
           })
             .then((response) => {
               if (response.data == "Status Changed") {
                 alert("Status Changed");
               } if(response.data == "Status not changed") {
                 alert("Status not changed");
               }
             })
             .catch((err) => {
               console.log(err);
             }):API({
              method: "POST",
              url: `/statusChange/${catName}/${IID}/${status}`,
              data:send
            })
              .then((response) => {
                if (response.data == "Status Changed") {
                  alert("Status Changed");
                } if(response.data == "Status not changed") {
                  alert("Status not changed");
                }
              })
              .catch((err) => {
                console.log(err);
              })
     
       }, []);
     return (
         <div>
               
         </div>
     );
}

export default StatusChange;