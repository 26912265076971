import React, { Fragment, useState, useEffect } from "react";
import MaterialTable from "material-table";

import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import FilterList from "@material-ui/icons/FilterList";
import AddBox from "@material-ui/icons/AddBox";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Save from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import axios from "axios";
import {getAPI} from './api.js';
import {useHistory} from 'react-router-dom'
import AddIcon from "@material-ui/icons/Add";
import "../css/ParticularModel.css";
import { AddEmployessModel } from "../components/ParticularModel";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  // DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Save: React.forwardRef((props, ref) => <Save {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),

  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  CheckIcon: React.forwardRef((props, ref) => (
    <CheckIcon {...props} ref={ref} />
  )),
  // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  ))
  // ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  // ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function CustomEditComponent() {
  const history = useHistory()
  const [ data, setData ] = useState([]);
  const [ state, setstate ] = useState(false);
  const [ loader, setLoader ] = useState(true);
  const tabs = ['webleads','payments','coupons','addemployees', 'certificates','users','mentors',"domain","pricingplan"];
  const sorter = (a, b) => {
    return tabs.indexOf(a)-tabs.indexOf(b)
    
 };

  const handleCheckBoxes = (a,b)=>{
   
    if(a.target.checked){

      let checkedValues = b.value!==undefined?[...b.value]:['users']
      checkedValues.push(a.target.value);
      if(!checkedValues.includes('users')){
        checkedValues.push('users');
      }
      checkedValues.sort(sorter);
      b.onChange(checkedValues);
      return
    }else {
      let checkedValues = b.value.filter(elem=>elem!==a.target.value)
     
      if(!checkedValues.includes('users')){
        checkedValues.push('users');
      }
      checkedValues.sort(sorter);
      b.onChange(checkedValues);
      return
    }
    
  }
  const FetchData = () => {
    const API  = getAPI();
    API({
      method:"get",
      url:"employees/all"  
  }).then((response) => {
    if(response.status==200){
      const Data = [...response.data];
     // const tabsString = JSON.stringify(tabs)
      setData(Data);
      setLoader(false);} else if(response.status==202){
        alert(response.data)
      }
    }).catch((err)=>{
      if( err.response && err.response.status===403){
        alert("Your Session got Expired. Please login again");
      history.push("/signIn");      
}  else{
   alert("Something went wrong. Please try again");
}
    })
  };

  useEffect(() => {
    FetchData();
  }, []);

  
  const tableColumns = [
    {title:'EmployeeID',field:'employeeid',editable:false,emptyValue: () => "----"},
    { title: "Firstname", field: "first_name" ,editable:false,emptyValue: () => "----"},
    { title: "Lastname", field: "last_employee" ,editable:false,emptyValue: () => "----"},
    { title: "Email", field: "email",editable:false,emptyValue: () => "----" },
    { title: "Contact", field: "phone" ,editable:false,emptyValue: () => "----"},
    
    { title: "Tabs", field: "vDashboard.tabs" ,emptyValue: () => "----",
    render:rowData=>{
      if(rowData.vDashboard!==undefined && rowData.vDashboard.tabs!==undefined){
        return  rowData.vDashboard.tabs.map((tab)=>tab+' ')
      }
    },
    customFilterAndSearch: (term, rowData) =>{
      let tabs = rowData.vDashboard!==undefined && rowData.vDashboard.tabs!==undefined?rowData.vDashboard.tabs:[]
       
      return JSON.stringify(tabs).includes(term)
    },
  editComponent: props=>{
    return   tabs.map((tab,i)=><><div key = {i}><input defaultChecked  ={props.value!==undefined && props.value.includes(tab)||tab==='users'} disabled = {tab==='users'}  onChange= {(e)=>{handleCheckBoxes(e,props)}} type = "checkbox"  value={tab}/><label>{tab}</label></div></>)}
},

  
    
  ];

  return (
    <Fragment>
      {loader ? (
        <div className="loading-page">
          <h1 className="loading-content" style={{ textAlign: "center" }}>
            We are getting things ready for you...{" "}
          </h1>
        </div>
      ) : (
        <MaterialTable
          columns={tableColumns}
          data={data}
          icons={tableIcons}
          title=""
         
          options={{
            search: false,
            filtering: true,
             rowStyle: rowData => ({
               backgroundColor: (rowData.vDashboard && rowData.vDashboard.tabs && rowData.vDashboard.tabs.length>1) ? '#2ae713' : '#ffffff'}),
            
            //   actionsColumnIndex: -1,
           
          }}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       const API = getAPI();
            //       API({method:'post',
            //              url:"update/employees",
            //              data:newData       
            //     })
            //         .then((response) => {
            //           if (response.status === 200) {
            //             alert(response.data)
            //           } else if(response.status === 201) {
            //             setData([ ...data, response.data ]);

            //             console.log("successfull");
            //           } else{
            //             alert(response.data)
            //           }
            //         })
            //         .catch((err) => {
            //           console.log(err);
            //         });

            //       resolve();
            //     }, 1000);
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if(newData.vDashboard.tabs.length<2){
                  alert('select at least one tab');
                  reject()
                }else{
                setTimeout(() => {
                  const API = getAPI();
                  API({method:'put',
                         url:"update",
                         data:newData       
                })
                    .then((response) => {
                     
                      if (response.status === 200) {
                        alert(response.data);
                        const dataUpdate = [ ...data ];
                    
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setData([ ...dataUpdate ]);
                      } else if(response.status === 201) {
                        alert(response.data)                      
                      } else{
                        alert(response.data)
                      }
                    })
                    .catch((err)=>{
                      if( err.response && err.response.status===403){
                        alert("Your Session got Expired. Please login again");
                      history.push("/signIn");      
                }  else{
                   alert("Something went wrong. Please try again");
                }
                    });
                  // console.log("old:", oldData);
                  // console.log("new:", newData);
                  // const dataUpdate = [ ...data ];
                  // const index = oldData.tableData.id;
                  // dataUpdate[index] = newData;
                  // setData([ ...dataUpdate ]);

                  resolve();
                }, 1000)};
              }),
            // onRowDelete: (oldData) =>
            //   new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       const API = getAPI()
            //       API({
            //         method:'delete',
            //         url:'delete',
            //         data : oldData.employeeId
            //       }).then((res)=>{

            //       }).catch((err)=>{
            //         if( err.response && err.response.status===403){
            //           alert("Your Session got Expired. Please login again");
            //         history.push("/signIn");      
            //   }  else{
            //      alert("Something went wrong. Please try again");
            //   }
            //       })
            //       const dataDelete = [ ...data ];
            //       const index = oldData.tableData.id;
            //       dataDelete.splice(index, 1);
            //       setData([ ...dataDelete ]);
                  
            //       resolve();
            //     }, 1000);
            //   })
          }}
        />
      )}
    </Fragment>
  );
}
