import React, { useState } from "react";
import "../css/Coupenpage.css";
import { ParticularForm, InflucencerForm } from "../components/InflucencerForm";
import CouponInfo from "../components/CouponInfo";
import Arrow from "../images/arrow.svg";

import IndiviualCoupon from "./../components/IndiviualCoupon";
import GlobalCoupon from "./../components/GlobalCoupon";

const Coupenpage = () => {
  const [screen, setScreen] = useState("firstscreen");
  const [modelTwo, setModelTwo] = useState(false);
  const [modelThird, setModelThird] = useState(false);

  const [showdata, setShowdata] = useState(false);

  const handelfnOne = () => {
    setModelTwo(true);
  };

  const handelfnOnedata = () => {
    setModelThird(true);
  };

  const modalClose = (e) => {
    // e.preventDefault()
    setModelTwo(false);
    setModelThird(false);
  };
  //     let getpost = null;
  // const getInPosts = (e)=>{
  //   getpost = e
  // }

  const handeldata = () => {
    setShowdata(!showdata);
  };
  return (
    <>
      <div
        className="Coupenpage"
        style={{ backgroundColor: "#ABABFD66", width: "83%",marginLeft:"17%"}}
      >
        <div className="status-bar">
          <p
            onClick={() => {
              setScreen("firstscreen");
              setShowdata(false);
            }}
          >
            Influencer
          </p>
          <p
            onClick={() => {
              setScreen("secondscreen");
              setShowdata(false);
            }}
          >
            Global
          </p>
          <p
            onClick={() => {
              setScreen("thirdscreen");
            }}
          >
            Individual
            {/* <img  className="bgpic" src={Arrow} onClick={()=>handeldata()}  alt="arrow"></img> */}
          </p>
        </div>
        {/* { showdata && screen =='thirdscreen' &&
        <div className="selectdrap" >

         <p className="ptag" onClick={handelfnOnedata}>Email</p>
         <p  className="ptag" onClick={handelfnOne}>Particular</p>


        </div >
          } */}

        {/* {
            modelThird &&  <InflucencerForm onClick={modalClose}  appFor ='email'/>

          } 
          {
            modelTwo && <ParticularForm onClick={modalClose}  appFor ='particular'/>
          }  */}

        <div className="status-show">
          <div className={screen === "firstscreen" ? "active" : null}></div>
          <div className={screen === "secondscreen" ? "active" : null}></div>
          <div className={screen === "thirdscreen" ? "active" : null}></div>
        </div>

        <div>
          {screen === "firstscreen" && <CouponInfo type={screen} />}

          {screen === "secondscreen" && <GlobalCoupon type={screen} />}

          {screen === "thirdscreen" && <IndiviualCoupon type={screen} />}
        </div>
      </div>
    </>
  );
};

export default Coupenpage;
