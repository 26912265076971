import React, { useEffect, useState } from "react";
import "../css/Navbar.css";
// import logo from "../images/logo.svg";
import logo from "../images/tn-nav.png";

import logOut from "../images/logout.svg";
import open from "../images/open-icon.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

const Navbar = () => {
  const history = useHistory();
  const datas = useSelector((state) => state.reducer);
  if (datas === []) {
    history.push("/signIn");
  }

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/signIn");
  };

  return (
    <>
      <div className="navbar">
        <div className="main-div">
          <div className="logo-div">
            <img src={logo} alt="logo" style={{ width: " 178px" }} />
          </div>
          {datas.map((ele, id) => {
            return (
                <Link to={ele.path} key={id} className="linkPath">
                  <div
                
                    className={
                      history.location.pathname === ele.path
                        ? "nav-div active-nav"
                        : "nav-div"
                    }
                   
                  >
                    <div className="nav-inner-div" >
                      <img className="nav-img"   src={ele.img} alt="nav-icon"  />
                      <p>{ele.title}</p>
                    </div>
                  </div>
                </Link>
            );
          })}

          <div className="logout">
            <button onClick={() => logout()}>
              <span>
                <img src={logOut} alt="logout" />
              </span>
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
