import React from 'react';
import Navbar from "./components/Navbar";
import {BrowserRouter as Router,Switch,Route ,useLocation,Redirect} from "react-router-dom";
import Home from './pages/coupenPage';
import WebLead from './pages/WebLeadPage';
import UsersPage from './pages/UsersPage';
import Login from './pages/registerUser';
import PaymentPage from "./pages/PaymentPage";
import AddemployeePage from "./pages/AddempoPage";
import Pageerror from './pages/page404'
import AddCertificates from "./pages/AddCertificates"
import GuardedRoute from './components/GaurdedRoute';
import {useSelector} from 'react-redux';
import ForgotPassword from './pages/forgotPage';
import ConfirmPassword from './pages/confirmPassword';
import  decodedToken from '../src/components/decodedToken.js'
import OurMentors from './pages/ourMentors';
import Domain from './components/domain/index';
import PricingPlan from './components/pricingPlan';



const Nav = () =>{  

  const location = useLocation();
  
  if(location.pathname !== '/' && location.pathname !== '/signIn' && (location.pathname!=='/forgot-password' && location.pathname.split('/')[1]!==`confirm-password`) && location.pathname !=='/notfound'){

  // if(location.pathname !== '/' && location.pathname !=='/notfound'){
    return <Navbar/>
  }
  else {
    return null
  }
 
}



function App() {
  
 const handleLoginPath = ()=>{
 
   let decodeToken = decodedToken();
  
   let path = decodeToken!=={} && decodeToken.tabs!==undefined?`/${decodeToken.tabs[0]}`:'/'
   return path
 }
   const useHandleIsAuth = (path)=>{
    const navData = useSelector(state=>state.reducer);
    // debugger;
   let navComponent  = navData.find(e=>e.path===path)
  let auth = navComponent!==undefined?true:false
    return auth
 }

  return(
    <>
    <Router>
       <Nav/>
      <Switch>
         
         <Route path="/" exact component = {(Login)} >
       {  localStorage.getItem("token")!==null?<Redirect  to={handleLoginPath()}/>:<Login/>}
         </Route>
        <GuardedRoute path = "/coupons" exact component = {Home} auth = {useHandleIsAuth('/coupons')}>

        </GuardedRoute>

        <GuardedRoute path = "/webleads" exact component = {WebLead} auth = {useHandleIsAuth('/webleads')}>

      </GuardedRoute>
      
      <GuardedRoute path = "/users" exact component = {UsersPage} auth = {useHandleIsAuth('/users')}>

       </GuardedRoute>

       <GuardedRoute path = "/payments" exact component = {PaymentPage} auth = {useHandleIsAuth('/payments')} >

       </GuardedRoute>

       <GuardedRoute path = "/mentors" exact component = {OurMentors} auth = {useHandleIsAuth('/mentors')}>

</GuardedRoute>

       <GuardedRoute path = "/addemployees" exact component = {AddemployeePage} auth = {useHandleIsAuth('/addemployees')}>

        </GuardedRoute>

        <GuardedRoute path = "/certificates" exact component = {AddCertificates} auth = {useHandleIsAuth('/certificates')}>

        </GuardedRoute>
        <GuardedRoute path = "/domain" exact component = {Domain} auth = {useHandleIsAuth('/domain')}>

          </GuardedRoute>
                  <GuardedRoute path = "/pricingplan" exact component = {PricingPlan} auth = {useHandleIsAuth('/pricingplan')}>

</GuardedRoute>
        <Route exact path = '/signIn' component={Login}></Route>

       <Route path = "/forgot-password" exact component = {ForgotPassword}>

</Route>
<Route path = "/confirm-password/:key" exact component = {ConfirmPassword}>


</Route>
        {/* <Route exact path = "/*"  component = {Pageerror}> */}
        <Route exact path="/*">
            <Redirect to="/notfound" />
            <switch style ={{width:'100%'}}>
        <Route exact path = "/notFound"  component = {Pageerror}/>
              </switch>
          </Route>

        {/* <Route exact path = "/notfound" component = {Pageerror}> */}

        {/* </Route> */}
      </Switch>


      
 
     
    
    </Router>
    </>
  )




}

export default App;
