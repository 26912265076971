import axios from 'axios';


export const getAPI = () => {
  const token = localStorage.getItem('token');
  const createAPI = axios.create({
    baseURL: `https://server.teachnook.com/`,//`http://localhost:1998/`//// 'http://localhost:2201/',//https://vforbackend.verzeo.com/
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  return createAPI

}