import React, { useState, useEffect, useMemo } from "react";

import "../css/ParticularModel.css";
import {getAPI} from "./api";


export const Domain = (props) => {
  const [status, setStatus] = useState(0);
  const [counter, setCounter] = useState(0);

  const [domainData, setDomainData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [courseData, setCourseData] = useState([]);

  const [domainCheck, setDomainCheck] = useState([]);
  const [departmentCheck, setDepartmentCheck] = useState([]);
  const [coursesCheck, setCoursesCheck] = useState([]);
  const commmonData = {
    domain: [],
    department: [],
    course: [],
  };

  if (props.selectedData != undefined) {
    commmonData.domain = [...props.selectedData.domain];
    commmonData.department = [...props.selectedData.department];
    commmonData.course = [...props.selectedData.course];
  }

  const [selectedData, setSelectedData] = useState(commmonData);

  const updateData = () => {
    const API = getAPI();
    API.get("alldetails").then((response) => {
      const allData = response.data;
      const course_data = [...allData.course];
      const domain_data = [...allData.domain];
      const department_data = [...allData.department];
      domain_data.splice(3, domain_data.length);
      setDomainData(domain_data);
      setDepartmentData(department_data);
      setCourseData(course_data);
      if (props.selectedData != undefined) {
     
        let depArr = department_data.filter(
          (dep) => dep.domainDetails.IID === props.selectedData.domain[0]
        );
        let courseArr = [];
        depArr.forEach((dep) => {
          if (props.selectedData.department.includes(dep.IID)) {
            let cArr = course_data.filter(
              (course) => course.departmentDetails.IID === dep.IID
            );
            courseArr = [...courseArr, ...cArr];
          }
        });
        setDepartmentCheck(depArr);
        setCoursesCheck(courseArr);
      }
    }).catch((err)=>{
          if( err.response && err.response.status === 403){
            alert("Your session got expired.Please login again");
          }
    })
  };

  const checkBoxChange = (e, a) => {
    switch (a) {
      case "domain":
        let depArr = departmentData.filter(
          (dep) => dep.domainDetails.IID === e.target.value
        );
        let domArr = [e.target.value];
        setSelectedData({
          ...selectedData,
          ["domain"]: domArr,
          ["department"]: [],
          ["course"]: [],
        });
        setDepartmentCheck(depArr);
        setCoursesCheck([]);
        return;

      case "department":
        if (e.target.checked) {
          let courseCheckAddArr = courseData.filter(
            (course) => course.departmentDetails.IID === e.target.value
          );
          setCoursesCheck([...coursesCheck, ...courseCheckAddArr]);
          setSelectedData({
            ...selectedData,
            ["department"]: [...selectedData.department, e.target.value],
          });
        } else if (!e.target.checked) {
          let courseCheckAddArr = coursesCheck.filter(
            (course) => course.departmentDetails.IID != e.target.value
          );
          let depAddArr = selectedData.department.filter(
            (dep) => dep != e.target.value
          );
          let courseSelectAddArr = [];
          courseCheckAddArr.forEach((co) => {
            if (selectedData.course.includes(co.IID)) {
              courseSelectAddArr.push(co.IID);
            }
          });

          setCoursesCheck(courseCheckAddArr);
          setSelectedData({
            ...selectedData,
            ["department"]: depAddArr,
            ["course"]: [...courseSelectAddArr],
          });
        }
        return;
      case "course":
        if (e.target.checked) {
          setSelectedData({
            ...selectedData,
            ["course"]: [...selectedData.course, e.target.value],
          });
        } else if (!e.target.checked) {
          let courseSelectAddArr = selectedData.course.filter(
            (course) => course != e.target.value
          );
          setSelectedData({ ...selectedData, ["course"]: courseSelectAddArr });
        }

        return;
      default:
        return;
    }
  };
  const handleCounter = (e, a) => {
    a
      ? setCounter((prevCount) => prevCount + 1)
      : setCounter((prevCount) => prevCount - 1);
    e.preventDefault();
  };

  useEffect(() => updateData(), []);

  useMemo(() => props.applyFor(selectedData), [selectedData]);

  return (
    <>
      <div className="particular">
        <label className={counter === 0 ? "active1" : null}>Domain</label>
        <label className={counter === 1 ? "active1" : null}>Department</label>
        <label className={counter === 2 ? "active1" : null}>Courses</label>
      </div>
      <div className="domain">
        <div className="domain-model">
          <div className="domain-main">
            <div className="card-one">
              {counter === 0 ? (
                domainData && domainData.length > 0 ? (
                  domainData.map((ele) => {
                    return (
                      <div className="course">
                        <input
                          className="input-box"
                          name="domainName"
                          type="radio"
                          defaultChecked={selectedData.domain.includes(ele.IID)}
                          value={ele.IID}
                          onClick={(e) => {
                            checkBoxChange(e, "domain");
                          }}
                        />
                        <p className="course-box">{ele.domainName}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>No Domains</p>
                )
              ) : null}

              {counter === 1 ? (
                departmentCheck.length > 0 ? (
                  departmentCheck.map((ele, id) => {
               
                    return (
                      <div className="course">
                        <input
                          className="input-box"
                          name="departmentName"
                          type="checkbox"
                          defaultChecked={selectedData.department.includes(
                            ele.IID
                          )}
                          value={ele.IID}
                          onChange={(e) => {
                            checkBoxChange(e, "department");
                          }}
                        />
                        <p className="course-box">{ele.departmentName}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>No Departments to Show</p>
                )
              ) : null}

              {counter === 2 ? (
                coursesCheck.length > 0 ? (
                  coursesCheck.map((ele) => {
                    return (
                      <div className="course">
                        <input
                          className="input-box"
                          type="checkbox"
                          value={ele.IID}
                          defaultChecked={selectedData.course.includes(ele.IID)}
                          onChange={(e) => {
                            checkBoxChange(e, "course");
                          }}
                        />
                        <p className="course-box">{ele.courseName}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>No Courses To Show</p>
                )
              ) : null}
              <div>
                {counter < 2 && (
                  <button
                    className="course-btn"
                    onClick={(e, a = true) => handleCounter(e, a)}
                  >
                    Next
                  </button>
                )}
                {counter > 0 && (
                  <button
                    className="course-btn"
                    onClick={(e, a = false) => handleCounter(e, a)}
                  >
                    Previous
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
