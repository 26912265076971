import decodedToken from '../components/decodedToken';
import  profile from '../images/Icon awesome-user-circle.svg' 
import '../css/User.css'

const UsersPage = () => {

let decodeToken = decodedToken();

  return (
    <div className = "userSection">
       
         {decodeToken && <div className = "userProfile"> 
         <div className= "userProfileHeading"><img src ={profile}/></div>
         
         <div className = "userDetails">
         <div className= "userInfo"><label>Name:</label><p>{decodeToken.firstName+decodeToken.lastName}</p></div>
         <div className= "userInfo"><label>Department:</label><p>{decodeToken.department}</p></div>
         <div className= "userInfo"><label>Designation:</label><p>{decodeToken.designation}</p></div>
         <div className= "userInfo"><label>Email:</label><p>{decodeToken.email}</p></div>   
         </div>    
         </div>
                          
         
         }
        
    </div>
  );
};
export default UsersPage;
