import React, { useState, useEffect } from 'react';
import '../domain/global.css'
import { getAPI } from '../api';
import CreatePricingPlan from './createPricingPlan';
import ViewPlan from './viewPlan';
function PricingPlan() {
    const [pricingPlan, setPricingPlan] = useState([])
    const [action, setAction] = useState("")
    const [statusItem, setStatusItem] = useState('');
    const [currentPlan, setCurrentPlan] = useState({ pricingPlanName: "", pricingPlanID: "" })
    const API = getAPI()
    const handleStatus = (IID, status) => {
        setStatusItem({ IID, status })

    }
    const handleView = (id, name) => {
        setCurrentPlan({ pricingPlanName: name, pricingPlanID: id })
        setAction("view")
    }
    useEffect(() => {
        API.get('/pricingplan/getallplans')
            .then((response) => {
                setPricingPlan(response.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div style={{
            marginLeft: "20%",
            marginTop: "4%",
            width: "80%"
        }} >
            {action === "view" ? <ViewPlan pricingPlanID={currentPlan.pricingPlanID} setAction={setAction} /> : <>
                <p
                    className="btn .btn-primary create-btn"
                    style={{ cursor: "pointer", display: "inline-block", marginTop: "10px" }}
                    onClick={() => {
                        setAction("create");
                    }}
                >
                    Create Pricing Plan
                </p>
                <br />
                {pricingPlan != [] ?

                    pricingPlan.map((planData, index) => {
                        return (<div className="col-3" key={'stat-' + index} style={{ display: 'inline-block', margin: '15px' }} >
                            <div>

                                <div className="">
                                    <div className="card" >
                                        <div className="card-body m-10px" >
                                            {/* <span className='cardstyle' style={planData.isActive==true ? {backgroundColor:'green'} : {backgroundColor:'red'}} > {planData.isActive == true?  'Active' : 'In-Active'}</span> */}
                                            <p> Pricing Plan: {planData.pricingPlanID}</p>
                                            <br />
                                            <p> Pricing Plan: {planData.pricingPlanName}</p>
                                        </div>

                                        <div className="card-footer m-10px changes" style={{ display: "inline-block" }}>
                                            <button className="btn btn-primary sudden" onClick={() => handleView(planData.pricingPlanID, planData.pricingPlanName)}>View</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        )


                    })

                    :
                    <div className="col">
                        <div className="card" >
                            <p> No Data To Display </p>
                        </div>
                    </div>
                }
                {action === "create" ?
                    <div className="modal p-5pr" >
                        <span style={{ padding: '3px', float: 'right', backgroundColor: 'white ', borderRadius: '15px', fontWeight: '700', width: '20px', textAlign: 'center', cursor: 'pointer' }} onClick={() => { setAction("") }} >X</span>

                        <CreatePricingPlan />
                    </div> : ""}
            </>
            }
        </div>
    );
}

export default PricingPlan;