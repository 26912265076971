import { InflucencerForm } from "./InflucencerForm";
import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
// import ReactPaginate from "react-paginate";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import "../css/CouponInfo.css";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import acStatus from "../images/Coupon-img.svg";
import inacStatus from "../images/inactive-coupon.svg";
import {useHistory} from 'react-router-dom'
import {getAPI} from './api.js';

import { InflucencerEdit } from "../components/EditModel";

const CouponInfo = (props) => {
  let screen = props.type;
  const history = useHistory();
  const [model, setModel] = useState(false);
  const [postsPerPage] = useState(18);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
// const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [editModel, seteditModel] = useState(false);
  const [filteredCoupon, setFilteredCoupon] = useState([]);
  //  let Dataaa = posts ;

  //  console.log(Dataaa , "state Data");

  const getAllPosts =  ()=>{
  const API = getAPI();
      API({
      method:'get',
      url:"coupon/coupontype/Influencer"
    }).then((res)=>{   
      if(res.status===200){
        const data = res.data;

    
      //  const endvalue = postsPerPage * offset;
      //  const startvalue = endvalue - postsPerPage;
      //  const slice = data.slice(startvalue, endvalue);
    
        setAllPosts(data);
   //     setPageCount(Math.ceil(data.length / postsPerPage));
      

      }

    }).catch((err)=>{
     
     
      if( err.response && err.response.status === 403) {
      
        alert('Your session got expired.Please login again');
      history.push("/signIn");
    }else{
      alert('Something went wrong.Please try again');

    }
    }) 
   
  }
  useEffect(() => {
    setFilteredCoupon(
      posts.filter((coupon) =>
        coupon.couponCode.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, posts]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     let len = search.length;

  //     if (len == 0) {
  //       axios.get("https://vforbackend.verzeo.com/coupon/coupontype/Influencer")
  //         .then((response) => {
  //           setAllPosts(response.data);
  //         });
  //     } else if (len >= 1)
  //       axios
  //         .get(
  //           `https://vforbackend.verzeo.com/coupon/particularCoupon/${search}`
  //         )
  //         .then((response) => {
  //           console.log(response.data);
  //           setAllPosts(response.data);
  //         });
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [search]);

  const onHandleDelete = (couponID) => {
    const API =getAPI();
    API({
      method:'delete',
      url:`coupon/deletecoupon/${couponID}`,
    }).then((response) => {
        if (response.status === 200) {
          getAllPosts();
        } 
      }).catch((err)=>{
      
               if(err.response && err.response.status===403){
                 alert("Your session got expired. Please Login again");
                history.push("/signIn");;
               }else{
                alert("Something went wrong. Please try again");
               }
      })
  };

  // const handlePageClick = (event) => {
  //   const selectedPage = event.selected;
  //   setOffset(selectedPage + 1);
  // };

  useEffect(() => {
    getAllPosts();
  }, []);

  const handelfn = () => {
    setModel(true);
  };

  const onclick = (e) => {
    // e.preventDefault()
    setModel(false);
  };

  const [singleCoupon, setSingleCoupon] = useState({});

  const modelEditClose = (e, ele) => {
    if (editModel == false) {
      setSingleCoupon(ele);
    }

    // e.preventDefault()
    seteditModel(!editModel);
  };

  var activeStatus = {
    // backgroundImage: "url( "+ {acStatus} +" )",
    backgroundImage: "url(" + acStatus + ")",
    backgroundSize: "cover",
  };
  var inactiveStatus = {
    backgroundImage: "url(" + inacStatus + ")",
    backgroundSize: "cover",
  };

  function FormRow() {
    return (
      <React.Fragment>
        {filteredCoupon.map((ele, id) => {
          return (
            <Grid item xs={2} style={{ marginTop: "2rem" }}>
              {/* {console.log(ele,"0000000000000000000000")}  */}
              <div
                key={id}
                className="card-image"
                style={ele.isActive ? activeStatus : inactiveStatus}
              >
                <div className="Edit-btn">
                  <DeleteForeverIcon
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        )
                      )
                        onHandleDelete(ele.couponCode);
                    }}
                  />

                  <EditIcon onClick={(e) => modelEditClose(e, ele)} />
                </div>

                <div className="tooltip">
                  <h1>{ele.couponCode}</h1>
                  {/* {ele.hasOwnProperty("flatDiscount") ? (
                    <p style={{ margin: "2% 0% 15% 0%" }}>
                      Flat Discount: {ele.flatDiscount}
                    </p>
                  ) : (
                    <p style={{ margin: "2% 0% 15% 0%" }}>
                      Discount: {ele.discountPercentage}%
                    </p>
                  )} */}
                  <div className="tooltiptext">
                    <div className="tooltip-left">
                      {/* <p>Created By:</p>
                      <p>Created On:</p> */}
                      <p>Starting Date:</p>
                      <p>Ending Date:</p>
                      <p>Plan type:</p>
                    </div>

                    <div className="tooltip-right">
                      {/* <p>{ele.createdBy.Name}</p>
                      <p>{ele.createdOn.split("T")[0]}</p> */}
                      <p>{ele.startingDate.split("T")[0]}</p>
                      <p>{ele.endingDate.split("T")[0]}</p>
                      <p>
                        <ul>
                          {ele.applicablePlanType.map((e, index) => {
                            return <li key={index}>{e}</li>;
                          })}
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </Grid>
          );
        })}

        {editModel && (
          <InflucencerEdit
            state={singleCoupon}
            type={screen}
            influModel={getAllPosts}
            editHandelClosed={modelEditClose}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <>
      {model && (
        <InflucencerForm
          type={screen}
          onClick={onclick}
          getInfoCP={getAllPosts}
        />
      )}
      <div className="CouponInfo">
        <div className="CouponInfo-1">
          <input
            id="search"
            type="search"
            placeholder="search CouponCode"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="icon-search"></div>
          <Typography>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <FormRow />
              </Grid>
            </Grid>
          </Typography>

          <div className="paginateData">
            {/* <ReactPaginate
 previousLabel={"previous"}
 nextLabel={"next"}
 breakLabel={"..."}
 breakClassName={"break-me"}
 pageCount={pageCount}
 onPageChange={handlePageClick}
 containerClassName={"pagination"}
 subContainerClassName={"pages pagination"}
 activeClassName={"active"} /> */}

            <button onClick={handelfn} className="couponinfo-btn">
              Create Coupon
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CouponInfo;
