import reducer from "./reducerData";
import modelReducer from "./modelReducer";
import { combineReducers } from "redux";
export * from './reducerActions'

const rootReducer = combineReducers({
  reducer,
  modelReducer
});
export default rootReducer;
