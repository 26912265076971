import React, { useEffect, useState } from "react";
import "../css/loginregister.css";
import Bgimg from "../images/bgimg.svg";
import { useLocation, useParams } from 'react-router';

import '../css/Navbar.css'
import axios from 'axios';
import {useDispatch} from 'react-redux';
import decodedToken from '../components/decodedToken'
import {useHistory} from 'react-router-dom';
import {loginAction} from '../reducer'
const emailPattern = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const pattern =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.*[!@#$%^&*]).{8,15}$/;
  

const ConfirmPassword=()=>{
    let {key}=useParams()
    let location=useLocation()
    // let token=
  const [error , setError] = useState(false)
  const dispatch = useDispatch();
const history = useHistory()
const [errMsg,setErrMsg]=useState("")
const [showForm,setShowForm]=useState(false)
 const [user , setUser]  = useState ({
      password :"",
      confirmPassword:""
 }) ;

 useEffect(()=>{
     let token=new URLSearchParams(location.search).get("token")
    axios({
        method: "get",
        url: `https://vforbackend.verzeo.com/auth/verify/${key}?token=${token}`,
        // data: user.email,
      })
        .then((res) => {
         // console.log(res,'loginData');
         // localStorage.setItem('token',res.data.token);
       //  let decodeToken  = decodedToken();
         //  debugger;
          if (res.status === 200) {    
              setShowForm(true)
         //  dispatch(loginAction(decodeToken.tabs));
        //   history.push("/signIn");
          }else if(res.status==202){
              setErrMsg(res.data)
              setError(true)
          }
        })
        .catch((err) => {
         
        });
 },)
 const handleChange = (e) => {
  const value = e.target.value
   setUser({...user , 
    [e.target.name] : value ,
})
setError(false)
// setErrmsg("")

}
const handleSubmit =  (e)=>{
  e.preventDefault();
  if(user.password==''){
      setErrMsg("passowrd should not be empty")
      setError(true)
      return
  }
   else if(!pattern.test(user.password)){
    setErrMsg(<u style={{color : "red",lineHeight:"150%"}}>
    <li>password should contain Minimum 8 characters</li>
    <li>Maximum 20 characters</li>
    <li>At least one uppercase character</li>
    <li>At least one lowercase character</li>
    <li> At least one digit</li>
    <li>At least one special character</li>
    </u>)
    setError(true);
    return
  }
//  else if(!pattern.test(user.ConfirmPassword)){
//     // setError(true);
//     return
//   }
 else if(user.password!==user.confirmPassword){
      setErrMsg("password didn't match")
    setError(true);
    return  
  }
  submitDetails(); 
}
const submitDetails = ()=>{
//   // let loginDetails =  postBodyConstructor(user);
//   setError(false)
user.key=key
  axios({
   method: "post",
   url: `https://vforbackend.verzeo.com/auth/resetPassword`,
   data: user,
 })
   .then((res) => {
    // console.log(res,'loginData');
    // localStorage.setItem('token',res.data.token);
  //  let decodeToken  = decodedToken();
    //  debugger;
     if (res.status === 200) {    
    //  dispatch(loginAction(decodeToken.tabs));
    setErrMsg('')
    setErrMsg(res.data)
    setError(true)
    setTimeout(()=>{
        history.push("/signIn");
    },1000)
     }
   })
   .catch((err) => {
  
   });
}
return(
  <>
  <div className="login-register">
  <div className="login-inner">
    <div className="login-form">

      {showForm?<form method = "POST" className="input-field">
      <h1 style={{textAlign: 'center',color: "#071e40", fontSize: "30px"}}>Confirm-Password</h1>
    <div> <input  type="password" placeholder="password" className="inputtext" name = "password"  value= {user.password} onChange={handleChange} />
    {/* {error?<u  style={{color : "red",lineHeight:"150%"}}>
        <li>password should contain Minimum 8 characters</li>
        <li>Maximum 20 characters</li>
        <li>At least one uppercase character</li>
        <li>At least one lowercase character</li>
        <li> At least one digit</li>
        <li>At least one special character</li>
        </u>:null} */}
    </div>
    <div> <input  type="password" placeholder="Confirm passowrd" className="inputtext" name = "confirmPassword"  value= {user.ConfirmPassword} onChange={handleChange} />
    </div>
    {/* <div><input   type="password"  placeholder="Password" className="inputtext" name = "password"  value= {user.password} onChange = {handleChange}/>
    <a href='/forgot'>
    <p style={{textAlign: 'right' }}>Forgot password?</p>
    </a>
    </div> */}
     {error && showForm?<p  style={{color : "red"}}>{errMsg}</p>:null}

     <button type="submit" onClick={handleSubmit} className="login-btn">Update</button>
     </form>:""}
    {error && !showForm? <p  style={{color : "red"}}>{errMsg}</p>:''}

    </div>

{/* {error?<p  style={{color : "red"}}>{errMsg}</p>:null} */}
    
    <div className="login-img">
      <img  src={Bgimg} style={{width: '69%'}}/>
    </div>
    


  </div> 


  </div>
  </>
)
}
export default ConfirmPassword