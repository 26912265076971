import React, { useState, useRef, useEffect, formRef } from 'react';
import { getAPI } from '../api';
import StatusChange from './statusChange';
function ViewPlan({ pricingPlanID, setAction }) {
  const API = getAPI()
  const [particular, setParticular] = useState([])
  const [details, setDetails] = useState({ type: "", pitchamount: "", publicamount: "", downpayment: "", label: "", benifit: [], SG: {}, US: {}, AE: {}, Europe: {} })
  const formRef = useRef(" ")
  const [status, setStatus] = useState("")
  const [editplan, setEditPlan] = useState(false)

  const getData = () => {
    API("/pricingplan/particularplan/" + pricingPlanID)
      .then((response) => {
        setParticular(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleChange = (e, type) => {
    if (type == 'obj') {
      details[e.target.name] = e.target.value
      setDetails({ ...details })
    } else {
      var def = [...details[e.target.name.substr(0, 7)]]
      def[type] = e.target.value
      setDetails({ ...details, benifit: [...def] })

    }
  }

  const handleCheck = (obj) => {
    for (let key in obj) {
      if (obj[key] === "") {
        alert(`${key} shouldn't be empty`);
        return false;
      }
    }
    return true;
  };
  const handleEdit = (item) => {
    let val = details;
    details.type = item.type;
    details.pitchamount = item.pitchamount;
    details.publicamount = item.publicamount;
    details.downpayment = item.downpayment
    details.benifit = item.benifit
    details.pricingPlanID = pricingPlanID
    details.label = item.label
    details.SG = item.SG
    details.US = item.US
    details.AE = item.AE
    details.Europe = item.Europe


    setDetails({ ...val, ...details });
    setEditPlan(true)


  }
  console.log(details, "itemm")

  const handleChangeCountryPricing = (e, type, ccid) => {
    if (type == 'obj') {
      details[ccid][e.target.name] = e.target.value
      setDetails({ ...details })
    }
  }
  const getCountryDetails = (item, action) => {

    let allPlans = []
    let singleCountryDetails = []

    for (const key in item) {
      if (key == "IN" || key == "US" || key == "AE" || key == "Europe" || key == "SG") {
        allPlans.push(key)

      }
    }

    for (let i = 0; i <= allPlans.length - 1; i++) {

      if (allPlans[i] in item) {
        item[allPlans[i]].country = allPlans[i]
        singleCountryDetails.push(item[allPlans[i]])
      }
    }


    return singleCountryDetails.map((dets, idx) => {

      return <>
        {action == "read" ? <div><b><p>{dets.country}</p></b><p>publicAmount:{dets.publicamount}</p><p>pitchAmount:{dets.pitchamount}</p><p>downPayment:{dets.downpayment}</p></div>
          : ""}
      </>
    })
  }

  useEffect(() => {
    getData()
  }, []);


  const handleClickEvent = (e) => {
    e.preventDefault()
    console.log()
    API({
      method: 'post',
      url: `/pricingplan/modify`,
      data: details,
    }).then((response) => {
      if (response.data == "Updated") {
        alert("Updated")
      }
      if (response.data == "Failed") {
        alert("Failed")
      }
    }).catch((err) => {
      console.log(err);
    })
  }




  let arrcountry = ["SG", "US", "IN", "AE", "Europe"]
  return (<>
    {particular != [] && particular.length > 0 ?
      <div className="row" style={{ fontFamily: "sans-serif", gap: "15px", flexWrap: "wrap" }}>
        <div className=" card" style={{ position: 'relative', width: "28%" }}>
          <span className="cursor-pointer" style={{ position: 'absolute', top: '3px', right: '15px' }} >
          </span>
          <p>
            <b>Pricing Plan ID:</b>
            {particular[0].pricingPlanID}
          </p>
          <br />
          <p>
            <b>Pricing Plan Name:</b>
            {particular[0].pricingPlanName}
          </p>
          <br />

          {/* <b>Created By</b>
                    <p>
                      {particular[0].createdBy.name}
                    </p>
                    <p>
                      {particular[0].createdBy.email}
                    </p> */}
          {/* <b>Courses</b>
                    {particular[0].courses.map((data)=>{
                          return(
                            <p>
                              *{data}<br/>
                            </p>  
                          )
                        })}
                    <br />   */}
        </div>
        {particular[0].planType.map((item) => {

          return (
            <div className=" card" style={{ position: 'relative', width: "28%" }}>
              {/* <span className='cardstyle' style={item.isActive == true ? { backgroundColor: 'green' } : { backgroundColor: 'red' }} > {item.isActive == true ? 'Active' : 'In-Active'}</span> */}
              <span className="cursor-pointer" style={{ position: 'absolute', top: '3px', right: '15px' }} >
                <span
                  className="pencil"
                  onClick={() => handleEdit(item)}
                ></span>
              </span>
              <p>
                <b>Type:</b>
                {item.type}
              </p>
              <br />

              <p>
                <b>Public Amount:</b>
                {item.publicamount}
              </p>
              <p>
                <b>Pitch Amount:</b>
                {item.pitchamount}
              </p>
              <p>
                <b>DownPayment:</b>
                {item.downpayment}
              </p>
              <p>
                <b>Label:</b>
                {item.label}
              </p>

              <b>Benifits:</b>
              {/* <button type="button" onClick={() => handleParticularitem(item.type)}>
                          +
                     </button>  */}
              {item.benifit.map((data) => {

                return (

                  <p>
                    {data}<br />
                  </p>
                )
              })}
              <br />
              {/* {getCountryDetails(item, "read")}
              {item.isActive == true ? <button className="btn btn-primary style_button newedit" onClick={() => setStatus(particular[0].pricingPlanID, "false", item.type)}  >De-Activate</button> : <button onClick={() => setStatus(particular[0].pricingPlanID, "true", item.type)} className="btn btn-primary style_button newedit">Activate</button>} */}
            </div>

          )
        })}
      </div> : ""}




    {editplan == true && details != {} ?
      <div>
        <div className="modal p-5pr" >
          <div className="display-block card margin-auto" style={{ width: '50%' }} >
            <div style={{ position: 'relative' }} >
              <span style={{ padding: '3px', float: 'right', backgroundColor: 'white ', borderRadius: '15px', fontWeight: '700', width: '20px', textAlign: 'center', cursor: 'pointer' }} onClick={() => { setEditPlan(false) }} >X</span>

              {details && <form onSubmit={(e) => handleClickEvent(e)} encType="multipart/form">

                <p><b>pricingPlanID:{particular[0].pricingPlanID}</b></p>
                <div className="form-default form-group mt-3p" >
                  <span className="lable color_secondary apply_styles"  >Type</span>
                  <input type="text" name="type" value={details.type} onChange={(e) => handleChange(e, "obj")} />
                </div>
                <div className="form-default form-group mt-3p" >
                  <span className="lable color_secondary apply_styles"  >Public Amount</span>
                  <input type="text" name="publicamount" value={details.publicamount} onChange={(e) => handleChange(e, "obj")} />
                </div>
                <div className="form-default form-group mt-3p" >
                  <span className="lable color_secondary apply_styles" >Pitch Amount</span>
                  <input type="text" name="pitchamount" value={details.pitchamount} onChange={(e) => handleChange(e, "obj")} />
                </div>

                <div className="form-default form-group mt-3p" >
                  <span className="lable color_secondary apply_styles" >Down Payment</span>
                  <input type="text" name="downpayment" value={details.downpayment} onChange={(e) => handleChange(e, "obj")} />
                </div>
                <div className="form-default form-group mt-3p" >
                  <span className="lable color_secondary apply_styles" >Label</span>
                  <input type="text" name="label" value={details.label} onChange={(e) => handleChange(e, "obj")} />
                </div>
                <div>
                  <b>Benifits</b>

                  {details.benifit.map((details, index) => {
                    return (

                      <div className="form-default form-group mt-3p" key={'benifit-' + index} >
                        <input type="text" name={"benifit" + index} value={details} onChange={(e) => handleChange(e, index)} />

                      </div>
                    )

                  })}
                  {arrcountry.map((ccid, idx) => {


                    return (
                      details[ccid] != undefined ?
                        <>
                          <div>
                            <b> <p>{ccid}</p></b>
                          </div>
                          <div className="form-default form-group mt-3p" >
                            <span className="lable color_secondary apply_styles" >Public Amount</span>
                            <input type="text" name="publicamount" value={details[ccid].publicamount} onChange={(e) => handleChangeCountryPricing(e, "obj", ccid)} />
                          </div>
                          <div className="form-default form-group mt-3p" >
                            <span className="lable color_secondary apply_styles" >Pitch Amount</span>
                            <input type="text" name="pitchamount" value={details[ccid].pitchamount} onChange={(e) => handleChangeCountryPricing(e, "obj", ccid)} />
                          </div>
                          <div className="form-default form-group mt-3p" >
                            <span className="lable color_secondary apply_styles" >DownPayment</span>
                            <input type="text" name="downpayment" value={details[ccid].downpayment} onChange={(e) => handleChangeCountryPricing(e, "obj", ccid)} />
                          </div>
                        </> : ""
                    )
                  })
                  }
                </div>
                <button className="btn btn-primary style_button " type="submit">Update</button>

              </form>}
            </div></div>
        </div>
      </div> : ""}

    {/* {editcourses!=[] && editco==true ?
                    <div className="modal p-5pr" >  
                    <div className="display-block card margin-auto" style={{width:'50%' }} >
                     <div style={{position:'relative'}} >
                     <span style={{ padding: '3px', float: 'right', backgroundColor: 'white ', borderRadius: '15px', fontWeight: '700', width: '20px', textAlign: 'center', cursor: 'pointer' }} onClick={() => { setEditcou(false) }} >X</span>
                    <div className="form-default form-group mt-3p"  >
                      <b>Courses</b>
                      <button type="button" onClick={() => handleAdd()}>
                                +
                           </button> 
                      { 
                    editcourses.map((cour , index)=>{
                      return(
                        <div>
                        <form  ref={formRef} encType="multipart/form">
                      <div className="form-default form-group mt-3p" >
                        <button type="button" onClick={() => handleRemove(index)}>
                            X
                            </button>
                     <input type="text" name={"courses"+index} value={cour}  onChange={(e)=>handleCourseEdit(e)}/>
                    
                     </div>
                     </form>

                     </div>
                      )
                      })  }

                     <p className="btn btn-primary style_button "   onClick={() => { handleUpdate() }} >Update</p>
                  
                     </div>
                     </div>
                     </div></div>
                  
                  :""} */}

    {/* {addbenifits==true ? <div className="modal p-5pr" >  
                  <div className="display-block card margin-auto" style={{width:'50%' }} >
                   <div style={{position:'relative'}} >
                   <span style={{ padding: '3px', float: 'right', backgroundColor: 'white ', borderRadius: '15px', fontWeight: '700', width: '20px', textAlign: 'center', cursor: 'pointer' }} onClick={() => { setAddbenifits(false) }} >X</span>
                  <div className="form-default form-group mt-3p"  >
                    <b>Benifits</b>
                   
                    <button type="button" onClick={() => handleAddBenifits()}>
                              +
                         </button> 
                 <form>
                 {newbenifits.map((hul,index)=>{
                   return(
                     <div>
                    <button type="button" onClick={() => handleRemove(index)}>
                    X
                    </button>
                 <input type="text" name={"benifits"+index} value={hul} onChange={(e)=>handleEditBenifits(e)} />
                   </div>
                 )
})}
                <p className="btn btn-primary style_button " onClick={() => { handleBenifitUpdate() }} >Update</p></form></div></div></div></div> : ""}
       */}
    {status != '' ? <StatusChange catName='pricingplan' IID={status} /> : ""}
  </>

  );
}
export default ViewPlan;