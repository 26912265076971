import React, { useState, useEffect } from "react";
import {getAPI} from './api.js';
import ReactPaginate from "react-paginate";
import "../css/CouponInfo.css";

import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ParticularForm, InflucencerForm } from "../components/InflucencerForm";
import { CouponModel } from "../components/ParticularModel";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import { InflucencerEdit } from "../components/EditModel";
import {useHistory} from "react-router-dom";
import acStatus from "../images/Coupon-img.svg";
import inacStatus from "../images/inactive-coupon.svg";

const IndiviualCoupon = (props) => {
  let screen = props.type;
  const history = useHistory();
  const [postsPerPage] = useState(12);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [modelTwo, setModelTwo] = useState(false);
  const [modelThird, setModelThird] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [infoModel, setinfoModel] = useState(false);
  const [indexIn, setIndexIn] = useState(0);
  const [editIndividual, setIndividualedit] = useState(false);
  const [singleCoupon, setSingleCoupon] = useState({});
  const [filteredCoupon, setFilteredCoupon] = useState([]);
  const [search, setSearch] = useState("");

  const modelEditClose = (e, ele) => {
    if (editIndividual == false) {
      setSingleCoupon(ele);
    }
    // e.preventDefault()
    setIndividualedit(!editIndividual);
  };
  const onHandleDelete = (coupCode) => {
  const API = getAPI();
    API.delete(`coupon/deletecoupon/${coupCode}`)
      .then((res) => {
        if (res.status === 200) {
          getAllPosts();
    
        
        }
      }).catch((err)=>{
      
        if(err.response.status===403){
          alert("Your session got expired.Please try again");
          history.push("/signIn");
        }else{
          alert("Something went wrong. Please Try again");
          getAllPosts();
        }
      })
  };

  const getAllPosts =  () => {
    const API = getAPI();
      API.get(`coupon/coupontype/Indivisual`)
      .then((res)=>{
       if(res.status===200){
        const data = res.data;

        const endvalue = postsPerPage * offset;
        const startvalue = endvalue - postsPerPage;
        const slice = data.slice(startvalue, endvalue);
    
        setAllPosts(data);
        setPageCount(Math.ceil(data.length / postsPerPage));

       }else{
         alert("No Copouns are Found")
       }
    }).catch((err)=>{
 
      if(err.response && err.response.status===403){
        alert("Your session got expired. Please login again");
       history.push("/signIn");
      }else{
        alert("Something went wrong. Please try again");
      }
    })
    
  };

  var activeStatus = {
    // backgroundImage: "url( "+ {acStatus} +" )",
    backgroundImage: "url(" + acStatus + ")",
    backgroundSize: "cover",
  };
  var inactiveStatus = {
    backgroundImage: "url(" + inacStatus + ")",
    backgroundSize: "cover",
  };

  useEffect(() => {
    setFilteredCoupon(
      posts.filter((coupon) =>
        coupon.couponCode.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, posts]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
  };

  const modalClose = (e) => {
    // e.preventDefault()
    setModelTwo(false);
    setModelThird(false);
  };

  const handelfnOne = () => {
    setModelTwo(true);
  };

  const handelfnOnedata = () => {
    setModelThird(true);
  };

  useEffect(() => {
    getAllPosts();
  }, [offset]);

  function FormRow() {
    return (
      <React.Fragment container spacing={2} style={{ marginTop: "2rem" }}>
        {filteredCoupon.map((ele, ind) => {
      

          return (
            <Grid item xs={2}>
              <div className="card-image" key={ind}  style={ele.isActive ? activeStatus : inactiveStatus}>
                <div
                  style={{
                    justifyContent: "space-between",
                    padding: "15% 15% 0% 15%",
                    display: "flex",
                  }}
                >
                  <DeleteForeverIcon
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        )
                      )
                        onHandleDelete(ele.couponCode);
                    }}
                  />

                  <EditIcon onClick={(e) => modelEditClose(e, ele)} />
                </div>

                <div className="tooltip">
                  {" "}
                  {ele.discountPercentage ? (
                    <>
                      {" "}
                      <h1>{ele.couponCode}</h1>
                      <h2 style={{ marginBottom: "15%" }}>
                        {ele.discountPercentage}%
                      </h2>{" "}
                    </>
                  ) : ele.flatDiscount ? (
                    <>
                      {" "}
                      <h1>{ele.couponCode}</h1>{" "}
                      <h2 style={{ marginBottom: "15%" }}>
                        {ele.flatDiscount}Rs.
                      </h2>{" "}
                    </>
                  ) : null}
                  <div className="tooltiptext">
                    <div className="tooltip-left">
                      {/* <p>Created By:</p>
                      <p>Created On:</p> */}
                      <p>Starting Date:</p>
                      <p>Ending Date:</p>
                      <p>Plan type:</p>

                      {/* <p style = {{marginTop : "26px"}} >Plan_type:</p> */}
                    </div>

                    <div className="tooltip-right">
                      {/* <p>{ele.createdBy.name}</p>
                      <p>{ele.createdOn.split("T")[0]}</p> */}
                      <p>{ele.startingDate.split("T")[0]}</p>
                      <p>{ele.endingDate.split("T")[0]}</p>
                      <p>
                        <ul>
                          {ele.applicablePlanType.map((e, index) => {
                            return <li key={index}>{e}</li>;
                          })}
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                {/* {indexIn==ind? 
                  
                     infoModel &&  <CouponModel/> :null} 
                 
                  <button onClick={()=>infoModelhandel(ind)}>Hover over me</button> */}
                {/* <p>{ele.title}</p> */}
              </div>
            </Grid>
          );
        })}
        {editIndividual && (
          <InflucencerEdit
            state={singleCoupon}
            type={screen}
            editHandelClosed={modelEditClose}
            influModel={getAllPosts}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <>
      {modelThird && (
        <InflucencerForm
          onClick={modalClose}
          appFor="email"
          getInfoCP={getAllPosts}
        />
      )}
      {modelTwo && (
        <InflucencerForm
          onClick={modalClose}
          appFor="particular"
          getInfoCP={getAllPosts}
        />
      )}

      <div className="CouponInfo">
        <div className="CouponInfo-1">
          <input
            id="search"
            type="search"
            placeholder="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />

          <Typography>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <FormRow />
              </Grid>
            </Grid>
          </Typography>

          <div class="emailParticular">
            <button class="couponinfo-btn" onClick={handelfnOnedata}>
              Email
            </button>
            <button class="couponinfo-btn" onClick={handelfnOne}>
              Particular
            </button>
          </div>

          {/* <ReactPaginate
       previousLabel={"previous"}
       nextLabel={"next"}
       breakLabel={"..."}
       breakClassName={"break-me"}
       pageCount={pageCount}
       onPageChange={handlePageClick}
       containerClassName={"pagination"}
       subContainerClassName={"pages pagination"}
       activeClassName={"active"} /> */}
        </div>
      </div>
    </>
  );
};
export default IndiviualCoupon;
