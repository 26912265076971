import React from 'react'
import TableMentor from "../components/TableMentor";

function OurMentors() {
    return (
        <div style={{ overflow: "auto", width: "83%",marginLeft:"17%"}}>
      <TableMentor/>
    </div>
    )
}

export default OurMentors
