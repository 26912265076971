import React, { useState, useEffect } from 'react';
import { getAPI } from '../api';
function CreatePricingPlan()
{
    const API = getAPI();
// const [regionFields, setRegionFields] = useState([{type:"",region:"SG",publicamount: "", pitchamount: "", downpayment: "" }])

  const [fields, setFields] = useState([{ type: "", publicamount: "", pitchamount: "", downpayment: "", benifits: [], status: "" }]);
  const [ids, setIds] = useState([])
  const [pricingstatus, setPricingStatus] = useState({})
  const [pricingname, setPricingName] = useState([""])
 


    useEffect(() =>
    {
    API.get("/pricingplan/allcourseid").then((response) => {
      setIds(response.data); }).catch((err) => {
      console.log(err);
    })

  }, []);


  function handleChange(i, event) {
    fields[i][event.target.name] = event.target.value
    setFields([...fields])

  }
  // function handleChangeRegion(i, event) {
  //   regionFields[i][event.target.name] = event.target.value
  //   setRegionFields([...regionFields])
  // }

 
  function handlePricingStatus(e) {
    setPricingStatus(e.target.value)

  }
  let pricingPlanName;
  function handlePricingname(e) {
    let indx = e.target.name.substr(4, 5)
    pricingPlanName = [...pricingname]
    pricingPlanName[indx] = e.target.value
    setPricingName([...pricingPlanName])
  }

  //adding all fields for creating new plan type in the pricing plan
  function handleAdd() {
    let values = { type: "", publicamount: "", pitchamount: "", downpayment: "", label: "", benifits: [], status: "" };
    fields.push(values);

    setFields([...fields]);


  }

//  async function handleAddRegionFields() {
//     let values = { region:"SG",publicamount: "", pitchamount: "", downpayment: "" };
//     regionFields.push(values);
//     // console.log(regionFields,"fields")
//     setRegionFields([...regionFields]);
   
//   }
  const handleClickEvent = () => {

  
    // fields.push({ "cDetails": regionFields })
    fields.push({ "pricingPlanName": pricingname[0] })
    fields.push({ "pricingplanstatus": pricingstatus })

    console.log(fields, "fields")
    API({
      method: 'post',
      url: '/pricingplan/create',
      data: fields,
    }).then((response) => {
      if (response.data == "Pricing Plan successfully created") {
        alert("created")
      }

    }).catch((err) => {
      console.log(err);
    })
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  // function handleRemoveRegionFields(i) {
  //   const values = [...regionFields];
  //   values.splice(i, 1);
  //   setRegionFields(values);
  // }

  return (
      <div className="display-block card"  style={{ width: "70%",height:"100%", margin: "8px auto" ,display:"flex" ,gap:"32px"}}>
          <div className="leftContainer">
                        <h1>Create Pricing Plans</h1>




                        <button  className='btn' type="button" onClick={() => handleAdd()} style={{padding:"5px 20px" ,fontSize:"24px",lineHeight:"24px"}}>
            +
          </button>
          {fields.map((field, idx) => {
            return (
              <form>
                <div key={`${field}-${idx}`}>

                  <div style={{display:"flex"}}>
                                                        <button style={{padding:"5px 20px" ,fontSize:"20px",lineHeight:"24px"}} className='btn' type="button" onClick={() => handleRemove(idx)}>
                    x
                  </button>
                  
                  </div>
                                   {/* <h2>Indian</h2> */}
                                                                             <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Type</span>
                                      <select className='ml-10' onChange={e => handleChange(idx, e)} name="type">
                    <option value=''>Type</option>
                    <option value="selfPaced">Self Paced</option>
                    <option value="mentorLed">Mentor Led</option>
                    <option value="advanced">Advanced</option>
                    <option value="gold">Gold</option>
                    <option value="silver">Silver</option>
                    <option value="diamond">Diamond</option>
                  </select>
                  </div>
                                        <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Public Amount
                  </span>
                  <input
                    type="text"
                    placeholder="Public Amount"
                    name="publicamount"
                    value={field.publicamount}
                    onChange={e => handleChange(idx, e)}
                            />
                        </div>
                                              <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                  Pitch Amount
                  </span>
                  <input
                    type="text"
                    placeholder="Pitch Amount"
                    name="pitchamount"
                    value={field.pitchamount}
                    onChange={e => handleChange(idx, e)}
                  /></div>
                    <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Down Payment
                  </span>
                  <input
                    type="text"
                    placeholder="Down Payment"
                    name="downpayment"
                    value={field.downpayment}
                    onChange={e => handleChange(idx, e)}
                  />
                        </div>
                                       <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Label
                  </span>
                  <input
                    type="text"
                    placeholder="Label"
                    name="label"
                    value={field.label}
                    onChange={e => handleChange(idx, e)}
                  />
                  </div>
  
                  {/* status for plan type */}
                                                       <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Label
                  </span>
                  <select className='ml-10' onChange={e => handleChange(idx, e)} name="status">
                    <option value="">Select</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  </div>
                                                         <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Benifits of the pricing plan
                  </span>
                  <textarea type="text" name="benifits" value={field.benifits} onChange={e => handleChange(idx, e)} rows="10" cols="61" placeholder="enter benifits of the pricing plan in points">

                  </textarea>
</div>
                  
                
                </div>
                </form>
            )
                })
              }

</div>
          <div className="rightContainer">
              {/* <button className='btn btn-primary ' type="button" onClick={() => handleAddRegionFields()}>
                    Add Country PricingPlans
                  </button> */}
{/* {regionFields.map((regionfi, idx) => {
                    return (
                      <form>
                        <div key={`${regionfi}-${idx}`}>
                          <button className='btn btn-primary ' type="button" onClick={() => handleRemoveRegionFields(idx)}>Delete</button>
                   <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Type
                  </span>
                       <select  className='ml-10' onChange={e => handleChangeRegion(idx, e)} name="type">
                    <option value=''>Type</option>
                    <option value="selfPaced">Self Paced</option>
                    <option value="mentorLed">Mentor Led</option>
                    <option value="advanced">Advanced</option>
                    <option value="gold">Gold</option>
                    <option value="silver">Silver</option>
                    <option value="diamond">Diamond</option>
                  </select>
                </div>
                                          <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Region
                  </span>
                          <select className='ml-10' onChange={e => handleChangeRegion(idx,e)} name="region">
                            <option value="SG">Singapore</option>
                            <option value="US">United States</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="Europe">Europe</option>
                          </select>
                    </div>
                                                           <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Public Amount
                  </span>
                          <input
                            type="text"
                            placeholder="Public Amount"
                            name="publicamount"
                            value={regionfi.publicamount}
                            onChange={e => handleChangeRegion(idx, e)}
                            />
                          </div>
                                                              <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Pitch Amount
                  </span>   
                          <input
                            type="text"
                            placeholder="Pitch Amount"
                            name="pitchamount"
                            value={regionfi.pitchamount}
                            onChange={e => handleChangeRegion(idx, e)}
                            />
                          </div>
                                                                 <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Down Payment
                  </span>
                          <input
                            type="text"
                            placeholder="Down Payment"
                            name="downpayment"
                            value={regionfi.downpayment}
                            onChange={e => handleChangeRegion(idx, e)}
                          />
</div>

                        </div>
                      </form>
                    );
                  }
                  )} */}

                <div>

  
                                                 <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                     Pricing Plan Status
                  </span>
                  <select className='ml-10' onChange={e => handlePricingStatus(e)} name="statuspricingplan">
                    <option value="">Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
            </select>
            </div>
                                                 <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    PricingPlan Name
                  </span>
                  {pricingname.map((na, index) => {
                    return (
                      <input
                        type="text"
                        placeholder="Pricing Plan Name"
                        name={"name" + index}
                        value={na}
                        onChange={e => handlePricingname(e)}
                      />)
                  })
                  }
                  </div>
                </div>

                <p style={{}} className="btn btn-primary" onClick={() => { handleClickEvent() }} >Create</p>
        </div>
      </div>


    );
}

    export default CreatePricingPlan;