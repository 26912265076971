import React, { useState, useRef, useEffect } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteItem from "../deleteItem";
import { getAPI } from "../api";

const AllDomain = ({initial,formData, setFormData,setSelectedDomain,handleEdit,setSelectedDepartment,currentState,setCurrentState,pages,handleCreate,role,getData,isOpen, setOpen}) => {

let API=getAPI()

  const [pricingplan,setPricingPlans]=useState([])

  const [deleteItem, setDeleteItem] = useState("");
    const handleDelete = (IID,) => {
      if(window.confirm("Are you sure,you want to delete this")){
        setDeleteItem(IID);
    }else{
        alert("Your request has been cancelled")
    }
    };

    useEffect(()=>{
      API({
        method:"get",
        url: "/pricingplan/getallplans",
      })
        .then((response) => {
         setPricingPlans(response.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },[])
 
  const formRef = useRef("");

  // const imageHandler = (event) => {
  //   const file = event.target.files[0];

  //   setImage(file);
  // };

  const handleChange = (e) =>
  {
       setFormData({...formData,[e.target.name]:e.target.value})
    };
  const handleView = (id,name) =>
  {
    if (currentState === "Domain") {
      setSelectedDomain({domainId:id,domainName:name})
      setCurrentState("Department")
    }
    else if (currentState === "Department") {
      setSelectedDepartment({departmentId:id,departmentName:name,...setSelectedDomain})
      setCurrentState("Course")
    }
  }

  const handleBack = () =>
  {
       if (currentState === "Course") {
      setCurrentState("Department")
    }
    else if (currentState === "Department") {
      setCurrentState("Domain")
    }

  }

  return (
    <div className="domain" style={{marginTop:"2%" ,marginLeft:"20%",width:"80%"}}>
      {/* MODAL */}
      {isOpen === "create"||isOpen==="edit" ? (
        <div className="modal p-5pr">
          <div className="display-block">
            <div className="margin-auto card" style={{ width: "30%" ,margin:"auto"}}>
              <span
                style={{
                  padding: "3px",
                  float: "right",
                  backgroundColor: "white ",
                  borderRadius: "15px",
                  fontWeight: "700",
                  width: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen("");
                  setFormData(initial)
                }}
              >
                X
              </span>
              <form ref={formRef} id="form" encType="multipart/form" style={{padding: "20px"}}>
                <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    {currentState} Name
                  </span>
                  <input type="text" name="name" id="" placeholder="" required onChange={(e)=>handleChange(e)} value={formData.name}/>
                </div>
                <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    {currentState} Description
                  </span>
                  <input type="text" name="description" id="" placeholder="" required onChange={(e)=>handleChange(e)} value={formData.description}/>
                </div>
            {currentState==="Course"?    <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                <p>Pricing Plan </p>
              <select  name="pricingPlanID" onChange={(e)=>{handleChange(e)}} >
              <option   value="" >Select</option>
        {
      pricingplan.map((item)=>{
           return(
            <option  value={item.pricingPlanID} >{item.pricingPlanName}</option>
           )
       } )} 
      </select>
      </span>
      </div>:null}
                {/* <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    URL
                  </span>
                  <input type="text" name="URL" id="" placeholder="" required/>
                </div>
                <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Status
                  </span>
                  <select className="domainSelect" name="status" required>
                  <option >Select</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
                <div className="form-default form-group mt-3p">
                  <span className="lable color_secondary apply_styles">
                    Feature Image
                  </span>
                  <input
                    type="file"
                    name="img"
                    onChange={(event) => {
                      imageHandler(event);
                    }}
                  />
                </div> */}
                <div style={{margin: "20px",}}>
                  <p
                    className="btn btn-primary style_button"
                    style={{ width:"40%",padding:"2%",fontSize:"16px"}}
                    onClick={() => {
                     isOpen==="create"?handleCreate(formData):handleEdit(formData)
                    }}
                  >
                    {isOpen==="create"?"Create":"Edit"} {currentState}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="p-5pr" style={{ backgroundColor: "white" }}>
        {/* To create doamin form */}
        <div style={{ display: "flex", justifyContent: "space-between" ,alignItems:"center"}}>
          <div>
            {currentState!=="Domain"?<ArrowBackIcon style={{ fontSize: "24px",cursor:"pointer" }} onClick={handleBack}/>:null}
        <p
                  className="btn .btn-primary create-btn"
          style={{ cursor: "pointer", display: "inline-block",width:"170px"
    ,padding:"10px"}}
          onClick={() => {
            setOpen("create");
          }}
        >
          Create {currentState}
        </p></div>
       { pages && pages.length? <span style={{fontSize:"16px",width:"fit-content"}}>
            Number of {currentState}s :<b> {pages.length}</b>
            </span>:""}
        </div>

        {/* Get All Domains */}
  
        <div>
          {(pages && pages.length)? (
            pages.map((pageData, index) => {
              return (
                <div
                  className="col-3"
                  style={{ display: "inline-block", margin: "15px" }}
                  key={index}
                >
                  <div>
                    <div className="">
                      <div className="card">
                        {/* <span
                          className="cardstyle"
                          style={
                            pageData.isActive == true
                              ? { backgroundColor: "green" }
                              : { backgroundColor: "red" }
                          }
                        >
                          {" "}
                          {pageData.isActive == true ? "Active" : "In-Active"}
                        </span> */}
      

                        <div className="card-body m-10px">
                          <p> {currentState} ID: { pageData.IID}</p>
                        </div>
                        <div className="card-body m-10px">
                          <p> {currentState} Name : {currentState==="Domain"?pageData.domainName:currentState==="Department"?pageData.departmentName:pageData.courseName}</p>
                        </div>
                        {/* <div className="card-body m-10px">
                          <p> {currentState} URL: {pageData.URL}</p>
                        </div> */}
                        
                          <div className="card-footer  changes" style={{ display: "flex", justifyContent: "right", gap: "20px", padding: "10px" }}>
                          {currentState !== "Course" ?<p
                            style={{ width: "5%"}}
                            className="p-5px text-decoration-none  color_secondary tooltip"
                            onClick={() => { let name = currentState === "Domain" ? pageData.domainName : currentState === "Department" ? pageData.departmentName : pageData.courseName; handleView(pageData.IID,name)}}
                          > <span className="tooltiptext" style={{ left:"255%"}}>View</span>
                            
                           <img style={{width:"15px"}}src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==" />
                          </p>:null}
                      
                     
                         {/* {role==="admin" || role==="root"?
                         
                          <div className="tooltip" style={{marginLeft:"5%"}}>
                          <img src="https://img.icons8.com/material-rounded/24/000000/trash.png"  onClick={() => handleDelete(pageData.IID)} style={{cursor:"pointer"}}/>
                         <span className="tooltiptext" style={{left:"213%"}}>Delete</span>
                          </div>
                          :""}    */}
                        
                        {/* <img src="https://img.icons8.com/material-rounded/24/000000/trash.png"  onClick={() =>{ let name = currentState === "Domain" ? "domain": currentState === "Department" ? "department" : "course"; handleDelete(pageData.IID,name)} } style={{cursor:"pointer"}}/>
                        */}
                          <div
                            target="_blank"
                            className="tooltip"
                            
                            onClick={() => { setOpen("edit");let name = currentState === "Domain" ? pageData.domainName : currentState === "Department" ? pageData.departmentName : pageData.courseName; ;setFormData({name:name,description:pageData.description,IID:pageData.IID})}}
                          >
                            <img src="https://img.icons8.com/material-sharp/24/000000/pencil--v2.png"/>
                            <span className="tooltiptext" style={{left:"217%"}}>Edit</span>
                          </div>
                          
                      
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col">
              <div className="card">
                <p> No Data To Display </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Preloader */}
 
      {deleteItem != "" ? <DeleteItem catName={currentState.toLowerCase()} IID={deleteItem} getData={getData}/> : ""}
    
    </div>
  );
};


export default AllDomain;
