import React from "react";
import "../css/InflucencerForm.css";
import { useState } from "react";
import {getAPI} from './api.js';
import decodedToken from './decodedToken';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Domain } from "../components/ParticularModel";
import {useHistory} from 'react-router-dom';

export const InflucencerEdit = ({
  editHandelClosed,
  type,
  state,
  influModel,
}) => {
 
  const history = useHistory();
  // const [inputflat, setInputFlat] = useState(false);
  // const [inputper, setInputPer] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [link, setLink] = useState([]);

  // const [plantype, setPlantype] = useState([]);

  const [infoData, setInfodata] = useState(state);
  const [error, setError] = useState(false);
  const emailPattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  const numberPattern = new RegExp(/^[0-9]+$/);
  const rangePattern = new RegExp(/\b[1-9]\d{0,9}-\d{1,9}\b/);

  if (type === "firstscreen") {
    infoData.type = "Influencer";
  } else if (type === "secondscreen") {
    infoData.type = "Global";
  } else {
    infoData.type = "Indivisual";
  }

  const postBodyConstructor = (infoData) => {
    let fieldArray = [];
    let decoded = decodedToken();
    infoData.name =  decoded.firstName + decoded.lastName;
    infoData.email =  decoded.email;
    delete infoData.limitCounter;
    delete infoData.modifiedBy;
     
    for (let key in infoData) {

     

      let field = {};
      if (key === "status") {
        continue;
      }
      if (key === "startingDate" || key === "endingDate") {
        field["fieldName"] = key;
        field["fieldValue"] = infoData[key].split("T")[0];
        fieldArray.push(field);
        continue;
      }
      if (key === "isActive") {
        field["fieldName"] = "status";
        field["fieldValue"] = infoData[key] ? "Active" : "Inactive";
        fieldArray.push(field);
        continue;
      }
      field["fieldName"] = key;
      field["fieldValue"] = infoData[key];
      fieldArray.push(field);
    }
    return fieldArray;
  };

  const getToday = () => {
    let date = new Date().toLocaleDateString();
    let dateArr = date.split("/");
    let today = dateArr[2] + "-" + dateArr[0] + "-" + dateArr[1];
    return today;
  };

  const handleCheckBoxes = (e, a) => {
  

    if (e.target.checked) {
      infoData[a].push(e.target.value);
      setInfodata({ ...infoData, [a]: [...infoData[a]] });
      return;
    } else {
      if (infoData[a].includes(e.target.value)) {
        let newArr = infoData[a].filter((val) => val !== e.target.value);
        setInfodata({ ...infoData, [a]: newArr });
        return;
      }
      return;
    }
  };

  const handelInflucerModel = (e) => {
    let value = e.target.value;
    if (e.target.type === "radio") {
      value = e.target.value === "active" ? true : false;
    }
    if (e.target.name === "isActive") {
      setInfodata({
        ...infoData,
        isActive: e.target.value === "active" ? true : false,
      });
    }
  
    setInfodata({ ...infoData, [e.target.name]: value });
  };

  const handelInflucerSubmitEdit = () => {
  
    if (infoData.type!=='Influencer' &&
      infoData.flatDiscount !== undefined &&
      !numberPattern.test(infoData.flatDiscount)
    ) {
      setError(true);
      return;
    }
    if (!numberPattern.test(infoData.maximumLimit)) {
      setError(true);
      return;
    }

    if (
      infoData.applicableFor !== undefined &&
      infoData.applicableFor.email !== undefined &&
      infoData.applicableFor.email.length === 0
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableFor !== undefined &&
      infoData.applicableFor.domain !== undefined &&
      (infoData.applicableFor.domain.length === 0 ||
        infoData.applicableFor.department.length === 0 ||
        infoData.applicableFor.course.length === 0)
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableFor !== undefined &&
      infoData.applicableFor.email !== undefined &&
      infoData.applicableFor.email.length > 0 &&
      !infoData.applicableFor.email.every((email) => emailPattern.test(email))
    ) {
      setError(true);
      return;
    }

    if (infoData.type!=="Influencer" && 
      infoData.discountPercentage !== undefined &&
      (!numberPattern.test(infoData.discountPercentage) ||
        !numberPattern.test(infoData.maximumDiscount))
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableOn.length === 0 ||
      infoData.applicablePlanType.length === 0
    ) {
      setError(true);
      return;
    }

    if (
      infoData.couponCode === "" ||
      infoData.couponDescription === "" ||
      infoData.couponPurpose === ""
    ) {
      setError(true);
      return;
    }

    if (!rangePattern.test(infoData.range)) {
      setError(true);
      return;
    } else if (rangePattern.test(infoData.range)) {
      let rangeArr = infoData.range.split("-");
      if (parseInt(rangeArr[0]) >= parseInt(rangeArr[1])) {
        setError(true);
        return;
      }
    }

    if (Date.parse(infoData.startingDate) === Date.parse(infoData.endingDate)) {
      alert("Starting Date and Ending Date Should not be Same");
      return;
    }

    if (Date.parse(infoData.startingDate) > Date.parse(infoData.endingDate)) {
      alert("Starting Date should not be greater than Ending Date");
      return;
    }

    submitDetails();
    setError(false);
  };

  const submitDetails = () => {
    setError(false);
    setLoading(true);
    let details = postBodyConstructor(infoData);
    const API =getAPI();
  
    API({
      method: "post",
      url: 'coupon/modifyCoupon',
      data: details
    })
      .then((res) => {
        setLoading(true);
        if (res.status === 200) {
          setTimeout(() => {
            influModel();
            editHandelClosed();
          }, 1000);
        } else {
          alert(" Please try again");
        }
      })
      .catch((err) => {

   
        if(err.response && err.response.status === 403){
          alert("Your session got expired.Please Login again");
         history.push("/signIn");
        }else{
          alert("Something went wrong. Please try again");
          setLoading(false);

        }
      });
  }

  // const Handleadd = (e) => {
  //   setInputFlat(true);
  //   setInputPer(false);
  // };

  // const Handleadd2 = (e) => {
    
  //     setInputPer(true);
  //     setInputFlat(false);
    
  // };

  const addEmail = () => {
    let values = { ...infoData };

    values.applicableFor.email.push("");

    setInfodata({
      ...infoData,
      ["applicableFor"]: {
        ...infoData.applicableFor,
        ["email"]: [...values.applicableFor.email],
      },
    });
  };

  const deleteMail = (index) => {
 
    let values = { ...infoData };
    values.applicableFor.email.splice(index, 1);
    setInfodata({
      ...infoData,
      ["applicableFor"]: {
        ...infoData.applicableFor,
        ["email"]: [...values.applicableFor.email],
      },
    });
  };

  const handelEmail = (e, index) => {
    let newEmailArr = [...infoData.applicableFor.email];
    newEmailArr[index] = e.target.value;
    setInfodata({
      ...infoData,
      ["applicableFor"]: { ...infoData.applicableFor, ["email"]: newEmailArr },
    });
  };

  return (
    <>
      <div className="InflucencerForm">
        <div className="InfluceForm" style={{ textAlign: "left" }}>
          <div className="Info-div">
            <button className="btn" onClick={editHandelClosed}>
              ✖
            </button>
            {infoData.applicableFor &&
              infoData.applicableFor.email !== undefined && (
                <>
                  <div className="emailLabelBlock">
                    {" "}
                    <label>Email</label>
                    <button className="btnemail" onClick={addEmail}>
                      Add Email
                    </button>
                  </div>
                  {infoData.applicableFor.email.map((ele, index) => {
                    return (
                      <div className="emailBar" key={index}>
                        <input
                          className="inputdata"
                          placeholder="Enter Email"
                          type="text"
                          name={`email${index}`}
                          value={ele}
                          onChange={(e) => handelEmail(e, index)}
                        />
                        <DeleteForeverIcon
                          style={{ padding: 0, marginLeft: 10 }}
                          onClick={() => deleteMail(index)}
                        />
                        {error &&
                        !emailPattern.test(
                          infoData.applicableFor.email[index]
                        ) ? (
                          <p style={{ color: "red" }}>Invalid Email</p>
                        ) : null}
                      </div>
                    );
                  })}
                  {error && infoData.applicableFor.email.length === 0 ? (
                    <p style={{ color: "red" }}>
                      Please give atleast one Email
                    </p>
                  ) : null}
                </>
              )}

            <label>Coupon Code</label>
            <input
              type="text"
              name="couponCode"
              placeholder="Enter Coupon Code"
              value={infoData.couponCode}
              onChange={handelInflucerModel}
            />
            {error && infoData.couponCode === "" ? (
              <p style={{ color: "red" }}>Please fill the above field</p>
            ) : null}
            <label>Coupon Purpose</label>
            <input
              type="text"
              className="coupenPurpose"
              name="couponPurpose"
              value={infoData.couponPurpose}
              onChange={handelInflucerModel}
            />
            {error && infoData.couponPurpose === "" ? (
              <p style={{ color: "red" }}>Please fill the above field</p>
            ) : null}
            <label>Coupon Descriptions</label>
            <textarea
              className="coupon-des"
              name="couponDescription"
              value={infoData.couponDescription}
              onChange={handelInflucerModel}
            ></textarea>
            {error && infoData.couponDescription === "" ? (
              <p style={{ color: "red" }}>Please fill the above field</p>
            ) : null}

            <div className="status" onChange={handelInflucerModel}>
              <label>status</label>
              <div className="col-flex">
                <input
                  type="radio"
                  id="a1"
                  name="isActive"
                  value="active"
                  defaultChecked={infoData.isActive ? true : false}
                  onChange={handelInflucerModel}
                />
                <label className="distance">Active</label>
              </div>
              <div className="col-flex">
                <input
                  type="radio"
                  id="a2"
                  name="isActive"
                  value="notActive"
                  defaultChecked={!infoData.isActive ? true : false}
                  onChange={handelInflucerModel}
                />
                <label className="distance">Inactive</label>
              </div>
            </div>
            <label> Starting Date </label>
            <input
              type="date"
              name="startingDate"
              min={getToday()}
              value={infoData.startingDate.split("T")[0]}
              onChange={handelInflucerModel}
            />
            <label> Ending Date </label>
            <input
              type="date"
              name="endingDate"
              min={infoData.startingDate.split("T")[0]}
              value={infoData.endingDate.split("T")[0]}
              onChange={handelInflucerModel}
            />

           {infoData.type!=='Influencer' ?<div className="discount">
              <label> Discount type </label>
              {infoData.flatDiscount!== undefined && (
                <div className="col-flex">
                  <input
                  
                    value="flat"
                    type="radio"
                    id="a1"
                    name="discountType"
                    checked={true}
                  />

                  <label className="distance">Flat Discount</label>

                  <input
                    type="text"
                    name="flatDiscount"
                    style={{ marginLeft: "80px" }}
                    placeholder="Enter In Rupees"
                    defaultValue={infoData.flatDiscount}
                    onChange={handelInflucerModel}
                  />
                </div>
              )}
              {error &&
              infoData.flatDiscount !== undefined &&
              !numberPattern.test(infoData.flatDiscount) ? (
                <p style={{ color: "red" }}>Please fill the above field</p>
              ) : null}
              {infoData.discountPercentage !== undefined && (
                <div className="col-flex">
                  <input
                  
                    value="percentage"
                    id="a2"
                    name="discountType"
                    type="radio"
                    checked={true}
                  />
                  <label className="distance">Discount Percentage</label>

                  <div className="input-two">
                    <input
                      type="text"
                      placeholder="%"
                      name="discountPercentage"
                      style={{ marginLeft: "34px" }}
                      value={infoData.discountPercentage}
                      onChange={handelInflucerModel}
                    />
                    <input
                      type="text"
                      placeholder="maximum Discount"
                      name="maximumDiscount"
                      style={{ marginLeft: "10px" }}
                      value={infoData.maximumDiscount}
                      onChange={handelInflucerModel}
                    />
                  </div>
                </div>
              )}
              {error &&
              infoData.discountPercentage !== undefined &&
              (!numberPattern.test(infoData.discountPercentage) ||
                !numberPattern.test(infoData.maximumDiscount)) ? (
                <p style={{ color: "red" }}>Please fill the above field</p>
              ) : null}
            </div>:null}

            <div className="applicable">
              <label> Applicable On </label>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicableOn"
                  value="website"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicableOn.includes("website")}
                />
                <label className="distance">Website</label>
              </div>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicableOn"
                  value="link"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicableOn.includes("link")}
                />{" "}
                <label className="distance">Link</label>
              </div>
              {error && infoData.applicableOn.length === 0 ? (
                <p style={{ color: "red" }}>
                  Select atleast on of the checkboxes
                </p>
              ) : null}
            </div>

            {infoData.applicableFor && infoData.applicableFor.domain && (
              <Domain
                selectedData={infoData.applicableFor}
                applyFor={(e) => {
             
                  setInfodata({ ...infoData, ["applicableFor"]: e });
                }}
              />
            )}
            {error &&
            infoData.applicableFor &&
            infoData.applicableFor.domain &&
            (infoData.applicableFor.domain.length === 0 ||
              infoData.applicableFor.department.length === 0 ||
              infoData.applicableFor.course.length === 0) ? (
              <p style={{ color: "red" }}>Please Select the Courses</p>
            ) : null}

            <label>Coupon Limits</label>
            <div className="coupanlimit">
              <div className="datavalue">
                <input
                  name="maximumLimit"
                  value={infoData.maximumLimit}
                  onChange={handelInflucerModel}
                />
              </div>
            </div>
            {error && !numberPattern.test(infoData.maximumLimit) ? (
              <p style={{ color: "red" }}>Invalid Copoun Limit</p>
            ) : null}

            <label>Money Range</label>

            <div className="moneyrange">
              <div>
                <p>Starting Range - Ending Range</p>
                <input
                  type="text"
                  name="range"
                  value={infoData.range}
                  onChange={handelInflucerModel}
                />
                {error && !rangePattern.test(infoData.range) ? (
                  <p style={{ color: "red", fontSize: "initial" }}>
                    Invalid Range
                  </p>
                ) : null}
                {error &&
                rangePattern.test(infoData.range) &&
                parseInt(infoData.range.split("-")[0]) >=
                  parseInt(infoData.range.split("-")[1]) ? (
                  <p style={{ color: "red", fontSize: "initial" }}>
                    Starting range must be less than Ending Range
                  </p>
                ) : null}
              </div>
              <div></div>
            </div>

            <div className="plantype">
              <label>Plan Type</label>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicablePlanType"
                  value="selfPaced"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicablePlanType.includes(
                    "selfPaced"
                  )}
                />
                <label className="distance">Self Paced</label>
              </div>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicablePlanType"
                  value="mentorLed"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicablePlanType.includes(
                    "mentorLed"
                  )}
                />
                <label className="distance">Mentor Led</label>
              </div>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicablePlanType"
                  value="advanced"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicablePlanType.includes(
                    "advanced"
                  )}
                />
                <label className="distance">Advanced</label>
              </div>
              {!(
                infoData.applicableFor !== undefined &&
                infoData.applicableFor.domain !== undefined
              ) ? (
                <>
                  <div className="col-flex">
                    <input
                      type="checkbox"
                      name="applicablePlanType"
                      value="silver"
                      onChange={(e) => {
                        handleCheckBoxes(e, e.target.name);
                      }}
                      defaultChecked={infoData.applicablePlanType.includes(
                        "silver"
                      )}
                    />
                    <label className="distance">Silver</label>
                  </div>
                  <div className="col-flex">
                    <input
                      type="checkbox"
                      name="applicablePlanType"
                      value="gold"
                      onChange={(e) => {
                        handleCheckBoxes(e, e.target.name);
                      }}
                      defaultChecked={infoData.applicablePlanType.includes(
                        "gold"
                      )}
                    />
                    <label className="distance">Gold</label>
                  </div>
                  <div className="col-flex">
                    <input
                      type="checkbox"
                      name="applicablePlanType"
                      value="diamond"
                      onChange={(e) => {
                        handleCheckBoxes(e, e.target.name);
                      }}
                      defaultChecked={infoData.applicablePlanType.includes(
                        "diamond"
                      )}
                    />
                    <label className="distance">Diamond</label>
                  </div>
                </>
              ) : null}
              {error && infoData.applicablePlanType.length === 0 ? (
                <p style={{ color: "red" }}>Select atleast one plan type</p>
              ) : null}
            </div>

            {error ? (
              <p className="detailsError" style={{ color: "red" }}>
                Please Fill the Details correctly
              </p>
            ) : null}

            <button className="create" onClick={handelInflucerSubmitEdit}>
              {loading ? <p>Editing....</p> : <p>Edit</p>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
