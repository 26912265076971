import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import WebLead from '../pages/WebLeadPage';


const Pageerror = () => {
  

    const linkStyle ={
        fontSize: "14px",
        textDecoration: "none",
        color: "white",
        fontWeight: 700,
        backgroundColor: "rgb(3, 18, 81)",
        padding: "1% 2%",
        borderRadius: "10px",
        ":hover": {
            backgroundColor: "#efefef"
        },
    }
    return(
        <>
            <div style={{display:"flex", width:"100%",flexDirection:"column", height:"550px", justifyContent:"center", alignItems:"center"}}>
                
                    <h1 style={{textAlign:"center", fontSize:"100px", color:"#031251", margin:"0%"}}>
                        404
                    </h1>
                    <p style={{textAlign:"center", color:"#031251", fontSize:"14px", margin:"0% 0% 2% 0%"}}>Sorry! Page Not Found</p>
                    <Link to="/" style={linkStyle}>Go to Home</Link>
            </div>
        </>
    )
}
export default Pageerror;