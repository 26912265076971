import Dashboard from "../images/Dashboard.svg";
import webleads from "../images/webleads.svg";
import Payment from "../images/Icon-payment.svg";
import coupn from "../images/coupn-icon.svg";
import People from "../images/Icon-people.svg";
import User from "../images/Icon-user.svg";
import Pricing from '../images/icon-pricing.svg';
import Domain from "../images/icon-domain.svg";
import Certificate from "../images/certificate.svg"
import decodedToken from '../components/decodedToken';


const styles = {
  className:'text-gray-600 block px-2 py-1 hover:underline hover:text-gray-900',
  activeClassName: 'underline text-gray-900'
}


const initialState = [
//   {
//   path: "/sdf",
//   img: Dashboard,
//   class : "active" ,
//   title: "Dashboard"
// },
{
  path: "/webleads",
  img: webleads,
  class : "active" ,
  title: "Web leads"
},
{
  path: "/payments",
  img: Payment,
  class : "active" ,
  title: "Web Payments"
},
{
  path: "/mentors",
  img: People,
  class : "active" ,
  title: "Our Mentors"
},
{
  path: "/coupons",
  img: coupn,
  class : "active" ,
  title: "Coupons"
},
{
  path: "/addemployees",
  img: People,
  class : "active" ,
  title: "Add Employees "
},
{
  path: "/certificates",
  img: Certificate,
  class : "active" ,
  title: "Add Certificates"
},
{
  path: "/users",
  img: User,
  class : "active" ,
  title: "User "
}
,{
  path: "/domain",
  img: Domain,
  class : "active" ,
  title: "Domain"
  }
,{
  path: "/pricingplan",
  img: Pricing,
  class : "active" ,
  title: "Pricing Plan"
}
]

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case "LOGIN":
      let newState = [];
      action.tabs.forEach(tab=>{
      let filterdState = initialState.filter(e=>e.path === `/${tab}`);   
      newState = [...newState,...filterdState]
      })    
      return newState
    //   case "LOGOUT":
    //  return initialState
    default:
      let oldState=[]
      let decodeToken  = decodedToken();
      let decodedKeys = Object.keys(decodeToken)
      let tabs = decodedKeys.length!==0?decodeToken.tabs:[] 
      if(tabs===[]){
        oldState=[]
      }else{
        tabs.forEach(tab=>{
          let filterdState = initialState.filter(e=>e.path === `/${tab}`);          
          oldState = [...oldState,...filterdState]
          })   
      }
    return oldState;
  }
};
export default reducer;
