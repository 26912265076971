import React, { useState}from 'react';
import './addemp.css'
import axios from 'axios';

  export const EditEmployee=({handleClose})=>
  {
    const initialState= {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        bank_account_name: '',
        bank_account_number: '',
        bank_branch: '',
        bank_name: '',
        current_address: '',
        date_of_birth: '',
        date_of_joining: '',
        department: 'IT',
        designation: '',
        employeeid: '',
        father_name: '',
        gender: '',
        ifsc: '',
        isActive: true,
        marital_status: '',
        mother_name: ' ',
        panCard: '',
        permanent_address: '',
        personalEmail: '',
        phone: ''
    }
    const [empDetails, setEmpDeatils] = useState(initialState)
    const handleChange = (e) =>
    {
        setEmpDeatils({...empDetails,[e.target.name]:e.target.value})
    }

    const handleCheck = (obj) => {
        for (let key in obj) {
          if (obj[key] === "") {
            //   debugger;
            alert("Error in " + key + " Entered");
            return false;
          }
        }
        return true;
      };
        
const handleSubmit = (e) => {
    e.preventDefault();
    if (handleCheck(empDetails)){
        console.log(empDetails);
    }
    // if (handleCheck(empDetails)) {
    //     axios({
    //         method: 'post',
    //         url: '/employees/create',
    //         data: { data: empDetails }
    //     })
    //         .then((response) => {
    //             if(response.status===200){

    //                 console.log("Success");

    //                 // options.setmodalopen('');
    //                 // options.getEmployeeData()
    //                 // setErrorMsg(response.data);
    //             }else{
    //                 // setModalData(initialData);
    //                 // options.setmodalopen('');

    //             }
    //         })
    //         .catch((e) => {
    //             // setModalData(initialData);
    //             // options.setmodalopen('');
    //             // setErrorMsg('something went wrong');
    //         });
    // }
};
        const convertToInputDate = (date) => {
        let dd = date.getDate() <= 9 ? `0` + date.getDate() : date.getDate();
        let mm =
            date.getMonth() + 1 <= 9
                ? `0` + (date.getMonth() + 1)
                : date.getMonth() + 1;
        let yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleFormReset = (e) => {
        handleClose("Close");
        setEmpDeatils(initialState)
    }
  return (
    <div className="container empEditForm">
      <div style = {{cursor:"pointer",padding:"1%",backgroundColor:"#c8c8c8",borderRadius:"50%",fontWeight:"600",fontSize:"13px",marginBottom:"3%",width:"2%",textAlign:"center"}}onClick={(e)=>handleFormReset(e)}>X</div>
      <form id="form" style={{ height: "70vh", overflowY: "scroll" }}>
                <div className="input-container">
        <label htmlFor="date-of-joining">Employee ID</label>
        <input onChange={(e)=>handleChange(e)}
        type="text"
        id="employeeid"
        name="employeeid"
        placeholder="Employee ID.."
                  required
        value={empDetails.employeeid}
      /></div>

                <div className="input-container">
        <label htmlFor="date-of-joining">First Name</label>
        <input  onChange={(e)=>handleChange(e)}
          type="text"
          id="first_name"
          name="first_name"
          placeholder="Name.."
         value={empDetails.first_name}
                  required
          /></div>
                         <div className="input-container">
        <label htmlFor="date-of-joining">Middle name</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="middle_name"
          name="middle_name"
          placeholder="Middle name.."
          value={empDetails.middle_name}
        />
</div>
       <div className="input-container">
        <label htmlFor="date-of-joining">Last name</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="last_name"
          name="last_name"
          placeholder="Last name.."
          required
           value={empDetails.last_name}
        />
        </div>
        <div className="input-container">
        <label htmlFor="date-of-joining">Email</label>
        <input onChange={(e)=>handleChange(e)}
          type="email"
          id="email"
          name="email"
          placeholder="Email.."
                  required
                        value={empDetails.email}
        />
        </div>
        <div className="input-container">
        <label htmlFor="date-of-joining">Phone number</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="phone"
          name="phone"
          placeholder="Phone number.."
                  required
                   value={empDetails.phone}
        />
        </div>
        <div className="input-container">
        <label htmlFor="date-of-joining">Personal Email</label>
        <input onChange={(e)=>handleChange(e)}
          type="email"
          id="personalEmail"
          name="personalEmail"
                  placeholder="Personal email.."
                       value={empDetails.personalEmail}
          required
        />
</div>
        <div className="input-container">
        <label htmlFor="date-of-joining">Date of Birth</label>
          <input onChange={(e)=>handleChange(e)}
            type="date"
            id="date_of_birth"
            name="date_of_birth"
            value={empDetails.date_of_birth!==""?convertToInputDate(new Date(empDetails.date_of_birth)):""}
            placeholder="D.O.B.."
                      required
          />
        </div>
        <div className="input-container">
         <label htmlFor="date-of-joining">Date of Joining</label>
          <input onChange={(e)=>handleChange(e)}
            type="date"
            id="date_of_joining"
         
            name="date_of_joining"
            placeholder="D.O.J.."
            required
                    value={empDetails?.date_of_joining!==""?convertToInputDate(new Date(empDetails.date_of_joining)):""}
          />
        </div>

           <div className="input-container">
         <label htmlFor="date-of-joining">Department</label>
        <select
          className='selectClass'
          id="department"
          name="department"
                 onChange={(e)=>handleChange(e)}
        >
          <option disabled   value>Select Your Department</option>
          <option value="IT">IT</option>
          <option value="Business Development">Business Development</option>
          <option value="Operations">Operations</option>
          <option value="Human Resource">Human Resource</option>
        </select>
</div>
{ empDetails.department ==="IT"&&<div id="IT">
          <label htmlFor="designation">Designation</label>
          <select className='selectClass' id="designation" name="designation" onChange={(e)=>handleChange(e)}>
            <option disabled   value>-- select an option --</option>
            <option value="Senior Digital Marketing Manager">
              Senior Digital Marketing Manager
            </option>
            <option value="Web developer">Web developer</option>
            <option value="Digital Marketing Associate">
              Digital Marketing Associate
            </option>
            <option value="Graphic Designer">Graphic Designer</option>
            <option value="Associate UI UX Designer">
              Associate UI UX Designer
            </option>
            <option value="Graphic Designer - Video Editor">
              Graphic Designer - Video Editor
            </option>
            <option value="Team Lead Content Creation and Marketing">
              Team Lead Content Creation and Marketing
            </option>
            <option value="Trainee software developer">
              Trainee software developer
            </option>
            <option value="Software Developer">Software Developer</option>
            <option value="Associate software developer">
              Associate software developer
            </option>
            <option value="Junior web developer">Junior web developer</option>
            <option value="Associate Vice President of Digital Marketing">
              Associate Vice President of Digital Marketing
            </option>
          </select>
        </div>}
       {empDetails.department ==="Business Development"&& <div id="Business_Development">
          <label htmlFor="designation">Designation</label>
          <select className='selectClass' id="designation" name="designation" onChange={(e)=>handleChange(e)}>
            <option disabled   value>-- select an option --</option>
            <option
              value="Assistant Associate Vice President of Business Development"
            >
              Assistant Associate Vice President of Business Development
            </option>
            <option
              value="Assistant Vice President of sales and marketing and HR Manager"
            >
              Assistant Vice President of sales and marketing and HR Manager
            </option>
            <option value="Team leader">Team leader</option>
            <option value="Senior Team Manager">Senior Team Manager</option>
            <option value="Business Development Associate">
              Business Development Associate
            </option>
            <option value="Business Development Executive">
              Business Development Executive
            </option>
            <option value="Assistant Associate Vice President of Sales">
              Assistant Associate Vice President of Sales
            </option>
            <option value="Assistant Vice President of Marketing & Branding">
              Assistant Vice President of Marketing & Branding
            </option>
            <option value="Corporate Relations Specialist">
              Corporate Relations Specialist
            </option>
            <option value="Corporate Relations Manager">
              Corporate Relations Manager
            </option>
            <option value="Business Analyst">Business Analyst</option>
            <option value="Senior Manager">Senior Manager</option>
          </select>
        </div>}
     {  empDetails.department ==="Operations"&& <div id="Operations">
          <label htmlFor="designation">Designation</label>
          <select className='selectClass' id="designation" name="designation" onChange={(e)=>handleChange(e)}>
            <option disabled   value>-- select an option --</option>
            <option value="Operations Executive">Operations Executive</option>
            <option value="Operations Associate">Operations Associate</option>
            <option value="Senior Operations Associate">
              Senior Operations Associate
            </option>
            <option value="Assistant Vice President of Operations">
              Assistant Vice President of Operations
            </option>
            <option value="Assistant Vice President of Operations">
              Assistant Vice President of Operations
            </option>
            <option
              value="Assistant Vice President of Operations and Administration"
            >
              Assistant Vice President of Operations and Administration
            </option>
          </select>
        </div>}
    {  empDetails.department==="Human Resource"  &&<div id="Human_Resource">
          <label htmlFor="designation">Designation</label>
          <select className='selectClass' id="designation" name="designation" onChange={(e)=>handleChange(e)}>
            <option disabled   value>-- select an option --</option>
            <option value="Senior HR Manager">Senior HR Manager</option>
            <option value="HR Executive">HR Executive</option>
          </select>
        </div>}
                  <div className="input-container">
         <label htmlFor="date-of-joining">PAN Number</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="panCard"
          name="panCard"
          placeholder="PAN number.."
                  required
                  value={empDetails.panCard}
        />
</div>
                         <div className="input-container">
         <label htmlFor="date-of-joining">Bank Name</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="bank_account_name"
          name="bank_account_name"
                  placeholder="Acc name.."
                  value={empDetails.bank_account_name}
          required
        />
</div>
                         <div className="input-container">
         <label htmlFor="date-of-joining">Bank Account Number</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="bank_account_number"
          name="bank_account_number"
          placeholder="Acc number.."
                  required
                  value={empDetails.bank_account_number}
        />
</div>
                                 <div className="input-container">
         <label htmlFor="date-of-joining">IFSC Number</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="ifsc"
          name="ifsc"
          placeholder="ifsc number.."
                  required
                  value={empDetails.ifsc}
        />
</div>
                                         <div className="input-container">
         <label htmlFor="date-of-joining">"Bank Name</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="bank_name"
          name="bank_name"
          placeholder="bank name.."
                  required
                  value={empDetails.bank_name}
        />
        </div>
                                                 <div className="input-container">
         <label htmlFor="date-of-joining">Bank Branch</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="bank_branch"
          name="bank_branch"
          placeholder="bank number.."
                  required
                   value={empDetails.bank_branch}
        />
        </div>

        <select className='selectClass' id="marital_status" name="marital_status" onChange={(e)=>handleChange(e)}>
          <option disabled   value> Select Your Martial Status</option>
          <option value="single">Single</option>
          <option value="married">Married</option>
        </select>

        
        <select className='selectClass' id="gender" name="gender" onChange={(e)=>handleChange(e)}>
          <option disabled   value>Select Your Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="others">Others</option>
        </select>
                                         <div className="input-container">
         <label htmlFor="date-of-joining">Father name</label>
        <input 
          type="text"
          id="father_name"
          name="father_name"
          placeholder="Father name.."
          value={empDetails.father_name}
          onChange={(e)=>handleChange(e)}
        />
</div>
                                              <div className="input-container">
         <label htmlFor="date-of-joining">Mother Name</label>
        <input onChange={(e)=>handleChange(e)}
          type="text"
          id="mother_name"
          name="mother_name"
          value={empDetails.mother_name}
          placeholder="Mother name.."
        />
        </div>
                                                      <div className="input-container">
         <label htmlFor="date-of-joining">Permanent Address</label>
        <textarea
          id="permanent_address"
          name="permanent_address"
          placeholder="Permanent address.."  value={empDetails.permanent_address}
          style={{ height: "100px" }}
          onChange={(e)=>handleChange(e)}
        ></textarea>
</div>
                                                             <div className="input-container">
         <label htmlFor="date-of-joining">Current  Address</label>
        <textarea
          id="current_address"
          name="current_address"
          placeholder="Current address.."
          value={empDetails.current_address}
          style={{ height: "100px" }}
          onChange={(e)=>handleChange(e)}
          ></textarea>
        </div>
        
        <input onChange={(e)=>handleChange(e)} type="hidden" name="key" value="" />
        <input type="submit" value="Submit" onClick={(e)=>handleSubmit(e,empDetails)} />
      </form>
    </div>
  );
}