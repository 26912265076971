import React, { Fragment, useState, useEffect } from "react";
import MaterialTable from "material-table";

import Edit from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ViewColumn from "@material-ui/icons/ViewColumn";
import FilterList from "@material-ui/icons/FilterList";
import AddBox from "@material-ui/icons/AddBox";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Save from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { getAPI } from "./api.js";
import { useHistory } from "react-router";
import jsPDF from "jspdf";
import "jspdf-autotable";

import "../css/Table.css";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  Save: React.forwardRef((props, ref) => <Save {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) =><><label>Export</label><SaveAlt {...props} ref={ref} /></>),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  CheckIcon: React.forwardRef((props, ref) => (
    <CheckIcon {...props} ref={ref} />
  )),

  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
  <><label>Show Columns</label><ViewColumn
      {...props}
      ref={ref} 
    /></>
  )),
};

export default function CustomEditComponent(props) {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [domainCheck, setDomainCheck] = useState([]);
  const [departmentCheck, setDepartmentCheck] = useState([]);
  const [coursesCheck, setCoursesCheck] = useState([]);
  const [domainToggle, setDomainToggle] = useState(false);
  const [deptToggle, setDeptToggle] = useState(false);
  const [courseToggle, setCourseToggle] = useState(false);
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const tableRef = React.createRef();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [go, setGo] = useState(false);
  const [selectedData, setSelectedData] = useState({
    domain: [],
    department: [],
    course: [],
  });

  const getAllDetails = () => {
    const API = getAPI();
    API.get("alldetails")
      .then((response) => {
        const allData = response.data;

        const course_data = [...allData.course];
        const domain_data = [...allData.domain];
        const department_data = [...allData.department];
        domain_data.splice(3, domain_data.length);
        setDomainData(domain_data);

        setDepartmentData(department_data);
        setCourseData(course_data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          alert("Your session got expired.Please login again");
          history.push("/signIn");
        }
      });
  };

  const FetchData = () => {
    const API = getAPI();
    API({ url: "websiteleads", method: "get" })
      .then((response) => {
        if (response.status == 200) {
          setData(response.data);
          setOriginalData(response.data);
        } else {
          setData([]);
        }

        setLoader(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          alert("Your session got expired. Please Login again");
          history.push("/signIn");
        } else {
          alert("Something went wrong.Please try again");
        }
      });
  };

  const handleDropDown = (e, a) => {
    switch (a) {
      case "domain":
        setDeptToggle(false);
        setCourseToggle(false);
        if (domainToggle) {
          setDomainToggle(false);
        } else {
          setDomainToggle(true);
        }
        return;

      case "department":
        setDomainToggle(false);
        setCourseToggle(false);
        if (deptToggle) {
          setDeptToggle(false);
        } else {
          setDeptToggle(true);
        }
        return;
      case "course":
        setDomainToggle(false);
        setDeptToggle(false);
        if (courseToggle) {
          setCourseToggle(false);
        } else {
          setCourseToggle(true);
        }
        return;
      default:
        return;
    }
  };

  const checkBoxChange = (e, a) => {
    switch (a) {
      case "domain":
        if (e.target.checked) {
          let depArr = departmentData.filter(
            (dep) => dep.domainDetails.IID === e.target.value
          );
          setSelectedData({
            ...selectedData,
            ["domain"]: [...selectedData.domain, e.target.value],
          });
          setDepartmentCheck([...departmentCheck, ...depArr]);
        } else if (!e.target.checked) {
          let domainSelected = selectedData.domain.filter(
            (dom) => dom !== e.target.value
          );

          let depCheckAddArr = departmentCheck.filter(
            (dep) => dep.domainDetails.IID !== e.target.value
          );

          let depSelected = [];
          depCheckAddArr.forEach((dep) => {
            if (selectedData.department.includes(dep.IID)) {
              depSelected.push(dep.IID);
            }
          });
          let courseCheckAddArr = [];
          if (depSelected.length !== 0) {
            depSelected.forEach((dep) => {
              let courseCheckFilter = coursesCheck.filter(
                (course) => course.departmentDetails.IID === dep
              );
              courseCheckAddArr = [...courseCheckAddArr, ...courseCheckFilter];
            });
          }

          let courseSelected = [];
          if (courseCheckAddArr.length !== 0) {
            courseCheckAddArr.forEach((co) => {
              if (selectedData.course.includes(co.IID)) {
                courseSelected.push(co.IID);
              }
            });
          }
          setDepartmentCheck(depCheckAddArr);
          setCoursesCheck(courseCheckAddArr);

          setSelectedData({
            ["domain"]: domainSelected,
            ["department"]: depSelected,
            ["course"]: courseSelected,
          });
        }
        return;

      case "department":
        if (e.target.checked) {
          let courseCheckAddArr = courseData.filter(
            (course) => course.departmentDetails.IID === e.target.value
          );
          setCoursesCheck([...coursesCheck, ...courseCheckAddArr]);
          setSelectedData({
            ...selectedData,
            ["department"]: [...selectedData.department, e.target.value],
          });
        } else if (!e.target.checked) {
          let courseCheckAddArr = coursesCheck.filter(
            (course) => course.departmentDetails.IID != e.target.value
          );
          let depAddArr = selectedData.department.filter(
            (dep) => dep != e.target.value
          );
          let courseSelectAddArr = [];
          courseCheckAddArr.forEach((co) => {
            if (selectedData.course.includes(co.IID)) {
              courseSelectAddArr.push(co.IID);
            }
          });

          setCoursesCheck(courseCheckAddArr);
          setSelectedData({
            ...selectedData,
            ["department"]: depAddArr,
            ["course"]: [...courseSelectAddArr],
          });
        }
        return;
      case "course":
        if (e.target.checked) {
          setSelectedData({
            ...selectedData,
            ["course"]: [...selectedData.course, e.target.value],
          });
        } else if (!e.target.checked) {
          let courseSelectAddArr = selectedData.course.filter(
            (course) => course != e.target.value
          );
          setSelectedData({ ...selectedData, ["course"]: courseSelectAddArr });
        }

        return;
      default:
        return;
    }
  };

  const filterByDomains = () => {
    let filteredData = [];

    if (selectedData.course.length !== 0) {
      selectedData.course.forEach((el) => {
        let filteredArr = originalData.filter(
          (rowData) => rowData.courseDetails.IID === el
        );
        filteredData = [...filteredData, ...filteredArr];
      });
      setData(filteredData);
      setDomainToggle(false);
      setDeptToggle(false);
      setCourseToggle(false);
      return;
    }

    if (selectedData.department.length !== 0) {
      selectedData.department.forEach((el) => {
        let filteredArr = originalData.filter(
          (rowData) => rowData.departmentDetails.IID === el
        );
        filteredData = [...filteredData, ...filteredArr];
      });
      setData(filteredData);
      setDomainToggle(false);
      setDeptToggle(false);
      setCourseToggle(false);
      return;
    }

    if (selectedData.domain.length !== 0) {
      selectedData.domain.forEach((el) => {
        let filteredArr = originalData.filter(
          (rowData) => rowData.domainDetails.IID === el
        );
        filteredData = [...filteredData, ...filteredArr];
      });
    } else if (selectedData.domain.length === 0) {
      filteredData = [...originalData];
    }
    setData(filteredData);
    setDomainToggle(false);
    setDeptToggle(false);
    setCourseToggle(false);
  };

  const tableColumns = [
    { title: "Name", field: "name", emptyValue: () => "----" },
    { title: "Email", field: "email", emptyValue: () => "----" },
    { title: "Contact", field: "phonenumber", emptyValue: () => "----" },
    {
      title: "Domain",
      field: "domainDetails.domainName",
      emptyValue: () => "----",
      // filterComponent: () => {
      //   return (
      //     <div className="FilterByComponent">
      //       <div
      //         className="FilterBy"
      //         onClick={(e) => {
      //           handleDropDown(e, "domain");
      //         }}
      //       >
      //         <ArrowDropDown />
      //       </div>
      //       {domainToggle && (
      //         <div className="filterCheckboxDropDown">
      //           {domainData && (
      //             <button
      //               className="filter-btn"
      //               onClick={(e) => {
      //                 filterByDomains(e);
      //               }}
      //             >
      //               Filter
      //             </button>
      //           )}
      //           {domainData &&
      //             domainData.map((el, i) => {
      //               return (
      //                 <div className="filterCheckbox">
      //                   {" "}
      //                   <input
      //                     key={i}
      //                     defaultChecked={selectedData.domain.includes(el.IID)}
      //                     value={el.IID}
      //                     type="checkbox"
      //                     onChange={(e) => {
      //                       checkBoxChange(e, "domain");
      //                     }}
      //                   />
      //                   <p className="filterName">{el.domainName}</p>
      //                 </div>
      //               );
      //             })}
      //         </div>
      //       )}
      //     </div>
      //   );
      // },
    },
    {
      title: "Department",
      field: "departmentDetails.departmentName",
      emptyValue: () => "----",
      // filterComponent: () => {
      //   return (
      //     <div className="FilterByComponent">
      //       <div
      //         className="FilterBy"
      //         onClick={(e) => {
      //           handleDropDown(e, "department");
      //         }}
      //       >
      //         <ArrowDropDown />
      //       </div>
      //       {deptToggle && (
      //         <div className="filterCheckboxDropDown">
      //           {departmentCheck.length > 0 ? (
      //             <button
      //               className="filter-btn"
      //               onClick={(e) => {
      //                 filterByDomains(e);
      //               }}
      //             >
      //               Filter
      //             </button>
      //           ) : null}
      //           {departmentCheck.length > 0 ? (
      //             departmentCheck.map((el, i) => {
      //               return (
      //                 <div className="filterCheckbox">
      //                   {" "}
      //                   <input
      //                     key={i}
      //                     defaultChecked={selectedData.department.includes(
      //                       el.IID
      //                     )}
      //                     value={el.IID}
      //                     type="checkbox"
      //                     onChange={(e) => {
      //                       checkBoxChange(e, "department");
      //                     }}
      //                   />
      //                   <p className="filterName">{el.departmentName}</p>
      //                 </div>
      //               );
      //             })
      //           ) : (
      //             <p>Select Domains</p>
      //           )}
      //         </div>
      //       )}
      //     </div>
      //   );
      // },
    },
    {
      title: "Course",
      field: "courseDetails.courseName",
      emptyValue: () => "----",
      // filterComponent: () => {
      //   return (
      //     <div className="FilterByComponent">
      //       <div
      //         className="FilterBy"
      //         onClick={(e) => {
      //           handleDropDown(e, "course");
      //         }}
      //       >
      //         <ArrowDropDown />
      //       </div>
      //       {courseToggle && (
      //         <div className="filterCheckboxDropDown">
      //           {coursesCheck.length > 0 && (
      //             <button
      //               className="filter-btn"
      //               onClick={(e) => {
      //                 filterByDomains(e);
      //               }}
      //             >
      //               Filter
      //             </button>
      //           )}
      //           {coursesCheck.length > 0 ? (
      //             coursesCheck.map((el, i) => {
      //               return (
      //                 <div className="filterCheckbox">
      //                   {" "}
      //                   <input
      //                     key={i}
      //                     defaultChecked={selectedData.course.includes(el.IID)}
      //                     value={el.IID}
      //                     type="checkbox"
      //                     onChange={(e) => {
      //                       checkBoxChange(e, "course");
      //                     }}
      //                   />
      //                   <p className="filterName">{el.courseName}</p>
      //                 </div>
      //               );
      //             })
      //           ) : (
      //             <p>Select Departments</p>
      //           )}
      //         </div>
      //       )}
      //     </div>
      //   );
      // },
    },
    {
      title: "Amount Paid",
      field: "amountPaid",
      render: (rowData) =>
        rowData.couponDetails
          ? rowData.couponDetails.paidAmount
          : rowData.pricingPlanDetails.publicamount,
      customFilterAndSearch: (term, rowData) => {
        let amountPaid = rowData.couponDetails
          ? rowData.couponDetails.paidAmount
          : rowData.pricingPlanDetails.publicamount;
        return amountPaid.includes(term);
      },
    },

    {
      title: "Copoun Code",
      field: "couponDetails.couponCode",
      emptyValue: () => "----",
    },
    {
      title: "Plan Type",
      field: "pricingPlanDetails.type",
      emptyValue: () => "----",
    },
    { title: "Gateway", field: "paymentGateway", emptyValue: () => "----" },
    {
      title: "PaymentRequestId",
      field: "paymentRequestId",
      render: (rowData) =>
        rowData.paymentGateway !== undefined &&
        rowData.paymentGateway === "Instamojo" &&
        rowData.paymentRequestId !== undefined
          ? rowData.paymentRequestId
          : "",
      customFilterAndSearch: (term, rowData) => {
        let paymentRequestId =
          rowData.paymentGateway !== undefined &&
          rowData.paymentGateway === "Instamojo" &&
          rowData.paymentRequestId !== undefined
            ? rowData.paymentRequestId
            : "";
        return paymentRequestId.includes(term);
      },
      emptyValue: () => "----",
    },
    { title: "PaymentId", field: "paymentId", emptyValue: () => "----" },
    { title: "Status", field: "status", emptyValue: () => "----" },

    {
      title: "Date (yyyy-mm-dd)",
      field: "createdOn",

      dateSetting: { locale: "en-GB" },
      filtering: true,
      emptyValue: () => "----",
    },
  ];

  const handleDate = (e) => {
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);
    } else if (e.target.name === "endDate") {
      setEndDate(e.target.value);
    }
  };

  const resetDate = () => {
    setStartDate("");
    setEndDate("");
  };

  const goBack = () => {
    resetDate();
    setPageSize(10); 
    setGo(false);
    FetchData();
  };

  const getTommorow = (e) => {
    let d = new Date(e);
    d.setDate(d.getDate() + 1);
    let date = d.toLocaleDateString();
    let dateArr = date.split("/");
    let tommorow = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
    return tommorow;
  };

  const FilterData = (e) => {
    if (startDate === "" || endDate === "") {
      alert("Please Fill Start Date and End Date");
      return;
    }

    if (Date.parse(startDate) === Date.parse(endDate)) {
      alert("Starting Date and Ending Date Should not be Same");
      return;
    }

    if (Date.parse(startDate) > Date.parse(endDate)) {
      alert("Starting Date should not be greater than Ending Date");
      return;
    }

    setLoader(true);
    setGo(true);
    const API = getAPI();
    let details = {
      from: startDate,
      to: endDate,
    };
    API({
      url: "paymentfiltering",
      method: "post",
      data: details,
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          setPageSize(res.data.length);
          setData(res.data);
          setOriginalData(res.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err.response && err.response.status == 403) {
          alert("Your session got expired. Please Login again");
          history.push("/signIn");
        } else {
          alert("Something went wrong. Please try again");
        }
      });
  };

  const downloadPdf = () => {
    var doc = new jsPDF("l", "px", [900, 600]);

    var elem = document.getElementsByClassName("MuiTable-root")[0];
    var res = doc.autoTableHtmlToJson(elem);
    doc.autoTable(res.columns, res.data);
    doc.save("Verzeo Payments");
  };

  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv")
      ? fileName
      : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(() => {
    if (tableRef.current === null) {
      return;
    }
    tableRef.current.dataManager.changePageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    getAllDetails();
    FetchData();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <div className="loading-page">
          <h1 className="loading-content" style={{ textAlign: "center" }}>
            We are getting things ready for you...{" "}
          </h1>
        </div>
      ) : (
        <>
          {/* <div className="DateFilterBlock">
            <div>
              <label>Start Date :</label>
              <input
                name="startDate"
                onChange={(e) => handleDate(e)}
                value={startDate}
                max={new Date().toLocaleDateString}
                type="date"
              />
            </div>
            <div>
              <label>End Date :</label>{" "}
              <input
                name="endDate"
                disabled={startDate === ""}
                min={startDate}
                onChange={(e) => handleDate(e)}
                value={endDate}
                type="date"
              />
            </div>
            <button name="dateFilter" onClick={(e) => FilterData(e)}>
              Filter
            </button>
            <button
              onClick={() => {
                resetDate();
              }}
            >
              Reset
            </button>
            {go && (
              <button
                onClick={() => {
                  goBack();
                }}
              >
                Go back
              </button>
            )}
          </div> */}
          <MaterialTable
            tableRef={tableRef}
            columns={tableColumns}
            data={data}
            icons={tableIcons}
            actions={[
            
            ]}
            title="Teachnook Payment Dashboard"
            localization={{
              toolbar: {
                exportCSVName: "Export as csv",
                exportPDFName: "Export as pdf!!",
              },
            }}
            options={{
              search: false,
              filtering: true,
              exportAll: false,
              emptyRowsWhenPaging: false,
              pageSize: pageSize,
              pageSizeOptions: [
                5,
                10,
                15,
                20,
                25,
                50,
                100,
                200,
                300,
                500,
                data.length,
              ],
              columnsButton: true,
              exportButton: {
                csv: true,
                pdf:true,
              },
              exportPdf:()=>downloadPdf(),
              exportCsv: (columns, data) => {
                data = tableRef.current.state.renderData;

                const headerRow = columns.map((col) => {
                  return col.title;
                });

                // Turn data into an array of string arrays, without the `tableData` prop
                const dataRows = data.map(({ tableData, _id, ...row }) => {
                  row.amountPaid = row.couponDetails
                    ? row.couponDetails.paidAmount
                    : row.pricingPlanDetails.publicamount;
                  row.couponCode = row.couponDetails
                    ? row.couponDetails.couponCode
                    : "";
                  row.paymentRequestId =
                    row.paymentGateway !== undefined &&
                    row.paymentGateway === "Instamojo" &&
                    row.paymentRequestId !== undefined
                      ? row.paymentRequestId
                      : "";
                  row.paymentId =
                    row.paymentId !== undefined ? row.paymentId : "";
                  row.status = row.status !== undefined ? row.status : "";
                  let dataArr = [
                    row.name,
                    row.email,
                    row.phonenumber,
                    row.departmentDetails.departmentName,
                    row.domainDetails.domainName,
                    row.courseDetails.courseName,
                    row.amountPaid,
                    row.couponCode,
                    row.pricingPlanDetails.type,
                    row.paymentGateway,
                    row.paymentRequestId,
                    row.paymentId,
                    row.status,
                    row.createdOn,
                  ];

                  return dataArr;
                });

                // Aggregate header data and 'body' data
                // Mirror default export behavior by joining data via
                // the delimiter specified within material table (by default comma delimited)
                const { exportDelimiter } = tableRef.current.props.options;
                const delimiter = exportDelimiter ? exportDelimiter : ",";
                const csvContent = [headerRow, ...dataRows]
                  .map((e) => e.join(delimiter))
                  .join("\n");

                // This mirrors the default export behavior where the
                // exported file name is the table title.
                const csvFileName = "Verzeo Payments";

                // Allow user to download file as .csv
                downloadCsv(csvContent, csvFileName);
              },
            }}
          />
        </>
      )}
    </Fragment>
  );
}
