const initialState = {
  update: 1
};
const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NEXT":
      state.update++;
      return {
        ...state,
        update: [ state.update ]
      };
    case "NEXT-1":
      state.update++;
      return {
        ...state,
        update: [ state.update ]
      };

    case "NEXT-2":
      state.update++;
      return {
        ...state,
        update: [ state.update ]
      };
    default:
      return {
        ...state,
        update: [ state.update ]
      };
  }
};
export default modelReducer;
