import { InflucencerForm } from "../components/InflucencerForm";
import "../css/CouponInfo.css";

import EditIcon from "@material-ui/icons/Edit";
import React, { useState, useEffect } from "react";
import {getAPI} from './api.js';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ReactPaginate from "react-paginate";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Prompt } from "react-router";
import {headers} from "./headers";
import { InflucencerEdit } from "../components/EditModel";
import {useHistory} from 'react-router-dom';
import acStatus from "../images/Coupon-img.svg";
import inacStatus from "../images/inactive-coupon.svg";
import debounce from "lodash.debounce";
import { keys } from "@material-ui/core/styles/createBreakpoints";

const GlobalCoupon = (props) => {
  let screen = props.type;
  const [modelThird, setModelThird] = useState(false);

  const [postsPerPage] = useState(18);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [editGlobal, seteditGloball] = useState(false);
  const [search, setSearch] = useState("");
  const history = useHistory();
  // const[Q , setQ] = useState("")
  const [filteredCoupon, setFilteredCoupon] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [loading, setLoading] = useState(false);

  const getAllPosts =  () => {
    const API =getAPI();
     API.get(
      "coupon/coupontype/Global"
    ).then((res)=>{
         if(res.status===200){
          const data = res.data;
          const endvalue = postsPerPage * offset;
          const startvalue = endvalue - postsPerPage;      
          const slice = data.slice(startvalue, endvalue);
          setAllPosts(data);
          setPageCount(Math.ceil(data.length / postsPerPage));
         }else{
           alert("No Copouns are Found")
         }
    }).catch((err)=>{
      if(err.response && err.response.status===403){
        alert("Your session got expired.Please Login again");
        history.push("/signIn");
      }else{
        alert("Something went wrong. Please try again");
      }
    })
    
  };

  var activeStatus = {
    // backgroundImage: "url( "+ {acStatus} +" )",
    backgroundImage: "url(" + acStatus + ")",
    backgroundSize: "cover",
  };
  var inactiveStatus = {
    backgroundImage: "url(" + inacStatus + ")",
    backgroundSize: "cover",
  };

  useEffect(() => {
    setFilteredCoupon(
      posts.filter((coupon) =>
        coupon.couponCode.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, posts]);

  useEffect(() => {
    getAllPosts();
  }, [offset]);

  //    useEffect(()=>{
  //      const timer=setTimeout(()=>{

  //       let len = search.length;

  //       if(len==0){
  //         axios.get('https://vforbackend.verzeo.com/coupon/coupontype/Global').then((response)=>{
  //        setAllPosts(response.data)
  //      })

  //       }
  //       else if(len>=1)
  //       axios.get(`https://vforbackend.verzeo.com/coupon/particularCoupon/${search}`).then((response)=>{
  //         console.log(response.data);
  //         setAllPosts(response.data)

  //       })

  //      },1000)

  // return(()=>{
  //   clearTimeout(timer)})

  //    },[search])

  const deleteData = (couponCode) => {
    const API = getAPI();
    API({
      method:'delete',
      url:`coupon/deletecoupon/${couponCode}`,
     
    }).then((res) => {
        if (res.status === 200) {
          getAllPosts();
        } 
      }).catch((err)=>{
           if(err.response && err.response.status===403){
             alert("Your session got expired. Please Login again")
           history.push("/signIn");
           }else{
            alert("Something went wrong. Please try again")
           }
      })
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
  };

  const handelfnTwo = () => {
    setModelThird(true);
  };

  // ====================================================
  //        Geting an object from the Bulk coupns array
  //=====================================================

  const [singleCoupon, setSingleCoupon] = useState({});

  const modelEditClose = (e, singleCPN) => {
    // New One
    if (editGlobal === false) {
      setSingleCoupon(singleCPN);
    }

    seteditGloball(!editGlobal);
  };

  const onclick = (e) => {
    setModelThird(false);
  };

  function FormRow() {
    return (
      <React.Fragment>
        <Grid container spacing={2} style={{ marginTop: "2rem" }}>
          {filteredCoupon.map((ele) => {
            return (
              <>
                <Grid item xs={2}>
                  <div className="card-image"  style={ele.isActive ? activeStatus : inactiveStatus}>
                    <div className="Edit-btn">
                      <DeleteForeverIcon
                        style={{ tooltip: "Delete" }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            )
                          )
                            deleteData(ele.couponCode);
                        }}
                      />
                      
                      <EditIcon onClick={(e) => modelEditClose(e, ele)} />
                    </div>

                    <div className="tooltip">
                      <div className="percentage-div">
                        {ele.discountPercentage ? (
                          <>
                            {" "}
                            <h1>{ele.couponCode}</h1>
                            <h2>{ele.discountPercentage}%</h2>{" "}
                          </>
                        ) : ele.flatDiscount ? (
                          <>
                            {" "}
                            <h1>{ele.couponCode}</h1>{" "}
                            <h2>{ele.flatDiscount}Rs.</h2>{" "}
                          </>
                        ) : null}
                      </div>
                      <div className="tooltiptext">
                        <div className="tooltip-left">
                          {/* <p> Created_By:</p>
                          <p> createdOn:</p> */}
                          <p>Starting Date:</p>
                          <p>Ending Date</p>
                          <p>Plan type:</p>

                          {/* <p style = {{marginTop : "26px"}} >Applicable_On:</p> */}
                        </div>

                        <div className="tooltip-right">
                          {/* <p> {ele.createdBy.Name}</p>
                          <p>{ele.createdOn.split("T")[0]}</p> */}
                          <p>{ele.startingDate.split("T")[0]}</p>
                          <p>{ele.endingDate.split("T")[0]}</p>
                          <p>
                            <ul>
                              {ele.applicablePlanType.map((e, index) => {
                                return <li key={index}>{e}</li>;
                              })}
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </Grid>
              </>
            );
          })}

          {editGlobal && (
            <InflucencerEdit
              state={singleCoupon}
              type={screen}
              editHandelClosed={modelEditClose}
              influModel={getAllPosts}
            />
          )}
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <>
      <>
        {" "}
        {modelThird && (
          <InflucencerForm
            type={screen}
            onClick={onclick}
            getInfoCP={getAllPosts}
          />
        )}
        <div className="CouponInfo">
          <div className="CouponInfo-1">
            <input
              id="search"
              type="search"
              placeholder="search CouponCode"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />

            <Typography>
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <FormRow />
                </Grid>
              </Grid>
            </Typography>
            <div className="paginateData">
              {/* <ReactPaginate previousLabel={"previous"}
                            nextLabel={"next"}
                       breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/> */}

              <button onClick={handelfnTwo} className="couponinfo-btn">
                Create Coupon
              </button>
            </div>
          </div>
        </div>
      </>
      );
    </>
  );
};

export default GlobalCoupon;
