import React, { Fragment, useState, useEffect } from "react";
// import MaterialTable from "material-table";
import MaterialTable from "material-table";
import DatePicker from "../components/DatePicker";
import { getAPI } from "./api.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LastPage from "@material-ui/icons/LastPage";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import "../css/ParticularModel.css";
import FilterList from "@material-ui/icons/FilterList";
import AddBox from "@material-ui/icons/AddBox";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Save from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import "../css/Navbar.css";
import "../css/Table.css";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { useHistory } from "react-router";
import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@material-ui/core";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  // DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Save: React.forwardRef((props, ref) => <Save {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) =><><label>Export</label> <SaveAlt {...props} ref={ref} /></>),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),

  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),

  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),

  CheckIcon: React.forwardRef((props, ref) => (
    <CheckIcon {...props} ref={ref} />
  )),
  // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  // ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => (
   <><label>Show Columns</label> <ViewColumn
      {...props}
      ref={ref}
      
    /></>
  )),
};

export default function CustomEditComponent(props) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  // const [response, setResponse] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [originalData, setOriginalData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [domainCheck, setDomainCheck] = useState([]);
  const [departmentCheck, setDepartmentCheck] = useState([]);
  const [coursesCheck, setCoursesCheck] = useState([]);
  const [domainToggle, setDomainToggle] = useState(false);
  const [deptToggle, setDeptToggle] = useState(false);
  const [courseToggle, setCourseToggle] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const tableRef = React.createRef();
  const [go, setGo] = useState(false);
 const API = getAPI();
  const options = [{ name: "Contact us", value: "contactus" }, {name:"Jobcon",value:"jobcon"}, {name:"Get it",value:"getit"}, {name:"Job callback",value:"jobcallback"},{name:"Callback",value:"callback"},{name:"Learn more",value:"learnmore"},{name:"career",value:"career"}]
  const staticDomainData = [
    { IID: "SrPem3", domainName: "Apply for job" },
    { IID: "kDVAje", domainName: "Campus Ambassador New" },
    { IID: "FfkOSp", domainName: "Franchise Page" },
    { IID: "tVHtgY", domainName: "Verzeo Contact Us Now" },
  ];
  const [selectedData, setSelectedData] = useState({
    domain: [],
    department: [],
    course: [],
  });
  const [tableColumns,setTableColumns] =useState([])
  const history = useHistory();

  const getAllDetails = () => {
    const API = getAPI();
    API.get("alldetails"
    )
      .then((response) => {
        const allData = response.data;

        const course_data = [...allData.course];
        const domain_data = [...allData.domain];
        const department_data = [...allData.department];
        domain_data.splice(3, domain_data.length);

        setDomainData([...domain_data, ...staticDomainData]);
        setDepartmentData(department_data);
        setCourseData(course_data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          alert("Your session got expired.Please login again");
        }
      });
  };
const toTitleCase=(str)=> {
  const newStr = str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    }
  );
  
  return newStr.split(/[\s_]+|(?=[A-Z])/).join(" ");
  }
  const getHeaders = (data) =>
  {
            let object = data[0]
            let cols =[]
           for (let key in object) {
              cols.push({title: toTitleCase(key), field: key,      render: rowData => (
        <div className="table-td">
             {rowData[key]}
        </div>
      ),})
    }
            setTableColumns(cols)
  }
  const FetchData = () => {
    setLoader(true);
    API({method:"post",url:"form-response"})  
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          if (res.data.length > 0) {
            getHeaders(res.data)
          }
          setData(res.data);
          setOriginalData(res.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          alert("Your Session got Expired. Please login again");
          history.push("/signIn");
        } else {
          alert("Something went wrong. Please try again");
        }
      });
  };

  const handleDropDown = (e, a) => {
    switch (a) {
      case "domain":
        setDeptToggle(false);
        setCourseToggle(false);
        if (domainToggle) {
          setDomainToggle(false);
        } else {
          setDomainToggle(true);
        }
        return;

      case "department":
        setDomainToggle(false);
        setCourseToggle(false);
        if (deptToggle) {
          setDeptToggle(false);
        } else {
          setDeptToggle(true);
        }
        return;
      case "course":
        setDomainToggle(false);
        setDeptToggle(false);
        if (courseToggle) {
          setCourseToggle(false);
        } else {
          setCourseToggle(true);
        }
        return;
      default:
        return;
    }
  };
  const handleChange = (event) => {
    setSelected(event.target.value);
       setLoader(true);
    API({method:"post",url:`form-response?q=${event.target.value}`})  
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
                  if (res.data.length > 0) {
            getHeaders(res.data)
          }
          setData(res.data);
          setOriginalData(res.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          alert("Your Session got Expired. Please login again");
          history.push("/signIn");
        } else {
          alert("Something went wrong. Please try again");
        }
      });
  };
  const checkBoxChange = (e, a) => {
    switch (a) {
      case "domain":
        let domObj = domainData.find(
          (dom) => dom.domainName === e.target.value
        );
        if (e.target.checked) {
          let depArr = departmentData.filter(
            (dep) => dep.domainDetails.IID === domObj.IID
          );

          setSelectedData({
            ...selectedData,
            ["domain"]: [...selectedData.domain, domObj.domainName],
          });
          setDepartmentCheck([...departmentCheck, ...depArr]);
        } else if (!e.target.checked) {
          let domainSelected = selectedData.domain.filter(
            (dom) => dom !== e.target.value
          );

          let depCheckAddArr = departmentCheck.filter(
            (dep) => dep.domainDetails.IID !== domObj.IID
          );

          let depSelected = [];
          depCheckAddArr.forEach((dep) => {
            if (selectedData.department.includes(dep.departmentName)) {
              depSelected.push(dep.departmentName);
            }
          });
          let courseCheckAddArr = [];
          if (depSelected.length !== 0) {
            depSelected.forEach((dep) => {
              let depObj = depCheckAddArr.find(
                (dObj) => dObj.departmentName === dep
              );
              let courseCheckFilter = coursesCheck.filter(
                (course) => course.departmentDetails.IID === depObj.IID
              );
              courseCheckAddArr = [...courseCheckAddArr, ...courseCheckFilter];
            });
          }

          let courseSelected = [];
          if (courseCheckAddArr.length !== 0) {
            courseCheckAddArr.forEach((co) => {
              if (selectedData.course.includes(co.courseName)) {
                courseSelected.push(co.courseName);
              }
            });
          }
          setDepartmentCheck(depCheckAddArr);
          setCoursesCheck(courseCheckAddArr);

          setSelectedData({
            ["domain"]: domainSelected,
            ["department"]: depSelected,
            ["course"]: courseSelected,
          });
        }
        return;

      case "department":
        let depObj = departmentCheck.find(
          (dObj) => dObj.departmentName === e.target.value
        );
        if (e.target.checked) {
          let courseCheckAddArr = courseData.filter(
            (course) => course.departmentDetails.IID === depObj.IID
          );
          setCoursesCheck([...coursesCheck, ...courseCheckAddArr]);
          setSelectedData({
            ...selectedData,
            ["department"]: [...selectedData.department, depObj.departmentName],
          });
        } else if (!e.target.checked) {
          let courseCheckAddArr = coursesCheck.filter(
            (course) => course.departmentDetails.IID !== depObj.IID
          );
          let depAddArr = selectedData.department.filter(
            (dep) => dep != depObj.departmentName
          );
          let courseSelectAddArr = [];
          courseCheckAddArr.forEach((co) => {
            if (selectedData.course.includes(co.courseName)) {
              courseSelectAddArr.push(co.courseName);
            }
          });

          setCoursesCheck(courseCheckAddArr);
          setSelectedData({
            ...selectedData,
            ["department"]: depAddArr,
            ["course"]: [...courseSelectAddArr],
          });
        }
        return;
      case "course":
        let courseObj = coursesCheck.find(
          (coObj) => coObj.courseName === e.target.value
        );
        if (e.target.checked) {
          setSelectedData({
            ...selectedData,
            ["course"]: [...selectedData.course, courseObj.courseName],
          });
        } else if (!e.target.checked) {
          let courseSelectAddArr = selectedData.course.filter(
            (course) => course != courseObj.courseName
          );
          setSelectedData({ ...selectedData, ["course"]: courseSelectAddArr });
        }

        return;
      default:
        return;
    }
  };

  const filterByDomains = () => {
    let filteredData = [];

    if (selectedData.domain.length !== 0) {
      selectedData.domain.forEach((domName) => {
        let filteredArr = originalData.filter(
          (rowData) => rowData.domain === domName
        );
        filteredData = [...filteredData, ...filteredArr];
      });

      let filterByDep = [];

      if (selectedData.department.length !== 0) {
        selectedData.department.forEach((depName) => {
          let filteredArr = filteredData.filter(
            (rowData) => rowData.department === depName
          );
          filterByDep = [...filterByDep, ...filteredArr];
        });
        filteredData = [...filterByDep];

        let filterByCourse = [];
        if (selectedData.course.length !== 0) {
          selectedData.course.forEach((coName) => {
            let filteredArr = filteredData.filter(
              (rowData) => rowData.course === coName
            );
            filterByCourse = [...filterByCourse, ...filteredArr];
          });
          filteredData = [...filterByCourse];
        }
      }
    } else if (selectedData.domain.length === 0) {
      filteredData = [...originalData];
    }
    setData(filteredData);
    setDomainToggle(false);
    setDeptToggle(false);
    setCourseToggle(false);
  };

  // const postBodyConstructor = (data) => {
  //   let fieldArray = [];
  //   for (let key in data) {
  //     let field = {};
  //     field["fieldName"] = key;
  //     field["fieldValue"] = data[key];
  //     fieldArray.push(field);
  //   }
  //   return fieldArray;
  // };

  const FilterData = (e) => {
    if (startDate === "" || endDate === "") {
      alert("Please Fill Start Date and End Date");
      return;
    }

    // if (Date.parse(startDate) === Date.parse(endDate)) {
    //   alert("Starting Date and Ending Date Should not be Same");
    //   return;
    // }

    if (Date.parse(startDate) > Date.parse(endDate)) {
      alert("Starting Date should not be greater than Ending Date");
      return;
    }

    setLoader(true);
    setGo(true);
    const API = getAPI();
    let details = {
      filterByField: [
        {
          fieldName: "createdOn",
          fieldValue: {
            from: startDate,
            operator: "range",
            to: endDate,
          },
        },
      ],
    };
    API({
      url: "form-response",
      method: "post",
      data: details,
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
                  if (res.data.length > 0) {
            getHeaders(res.data)
          }
          setPageSize(res.data.length);
          setOriginalData(res.data);
          setData(res.data);

          //          tableRef.current.onQueryChange({
          //    page: 0,
          //    pageSize: res.data.length
          //  });
          //  setResponse(res.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err.response && err.response.status == 403) {
          alert("Your session got expired. Please Login again");
          history.push("/signIn");
        } else {
          alert("Something went wrong. Please try again");
        }
      });
  };

  const handleDate = (e) => {
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);
    } else if (e.target.name === "endDate") {
      setEndDate(e.target.value);
    }
  };

  // const handlePage = (a=0,b=10) => {
  //   tableRef.current.onQueryChange({filters:[]});
  //   setPageNumber(a+1);
  //   setPageSize(b);
  //   let details = {pageNumber:a+1,pageRow:b}
  //   if(startDate!=='' && endDate!==''){
  //     details.filterByField =[{
  //       fieldName: "createdOn",
  //       fieldValue: {
  //           from:getTommorow(startDate),
  //           operator:"range",
  //           to:endDate
  //       }
  //     }]
  //   }
  //   setLoader(true);
  //   const API =getAPI();
  //   API({
  //     url: "form-response",
  //     method: "post",
  //     data:details
  //   }).then((res) => {
  //     setLoader(false);
  //     if (res.status === 200) {
  //        setData(res.data.page);
  //        setResponse(res.data);

  //   }else {
  //     setData([]);

  //   }
  //   }).catch((err)=>{
  //     console.log(err);
  //     setLoader(false)
  //     if(err.response && err.response.status==403){
  //       alert("Your session got expired. Please Login again")
  //      history.push("/signIn");
  //     }else{
  //       alert("Something went wrong. Please try again");
  //     }

  //   })

  // };

  const resetDate = () => {
    setStartDate("");
    setEndDate("");
  };

  const goBack = () => {
    resetDate();
    setPageSize(10);
    // tableRef.current.onQueryChange({
    //   filters: [],
    //   page: 0,
    //   pageSize: 10,
    // });
    setGo(false);
    // setPageNumber(1);
    FetchData();
  };

  const getTommorow = (e) => {
    let d = new Date(e);

    d.setDate(d.getDate() + 1);
    let date = d.toLocaleDateString();
    let dateArr = date.split("/");
    let tommorow = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];

    return tommorow;
  };

  //const domainLookup = {"internship":'Internship',"Pro-Degree":'Pro Degree',"Verzeo Kids":"Verzeo Kids"};

  // const tableColumns = [
  //   { title: "Firstname", field: "firstName", emptyValue: () => "----" },
  //   { title: "Lastname", field: "lastName", emptyValue: () => "----" },
  //   { title: "Email", field: "email", emptyValue: () => "----" },
  //   { title: "Contact", field: "contact", emptyValue: () => "----" },
  //   { title: "Collegename", field: "college", emptyValue: () => "----" },
  //   {
  //     title: "Domain",
  //     field: "domain",
  //     emptyValue: () => "----",
  //     // filterComponent: () => {
  //     //   return (
  //     //     <div className="FilterByComponent">
  //     //       <div
  //     //         className="FilterBy"
  //     //         onClick={(e) => {
  //     //           handleDropDown(e, "domain");
  //     //         }}
  //     //       >
  //     //         <ArrowDropDown />
  //     //       </div>
  //     //       {domainToggle && (
  //     //         <div className="filterCheckboxDropDown">
  //     //           {domainData && (
  //     //             <button
  //     //               className="filter-btn"
  //     //               onClick={(e) => {
  //     //                 filterByDomains(e);
  //     //               }}
  //     //             >
  //     //               Filter
  //     //             </button>
  //     //           )}
  //     //           {domainData &&
  //     //             domainData.map((el, i) => {
  //     //               return (
  //     //                 <div className="filterCheckbox">
  //     //                   {" "}
  //     //                   <input
  //     //                     key={i}
  //     //                     defaultChecked={selectedData.domain.includes(
  //     //                       el.domainName
  //     //                     )}
  //     //                     value={el.domainName}
  //     //                     type="checkbox"
  //     //                     onChange={(e) => {
  //     //                       checkBoxChange(e, "domain");
  //     //                     }}
  //     //                   />
  //     //                   <p className="filterName">{el.domainName}</p>
  //     //                 </div>
  //     //               );
  //     //             })}
  //     //         </div>
  //     //       )}
  //     //     </div>
  //     //   );
  //     // },
  //   },
  //   {
  //     title: "Department",
  //     field: "department",
  //     emptyValue: () => "----",
  //     // filterComponent: () => {
  //     //   return (
  //     //     <div className="FilterByComponent">
  //     //       <div
  //     //         className="FilterBy"
  //     //         onClick={(e) => {
  //     //           handleDropDown(e, "department");
  //     //         }}
  //     //       >
  //     //         <ArrowDropDown />
  //     //       </div>
  //     //       {deptToggle && (
  //     //         <div className="filterCheckboxDropDown">
  //     //           {departmentCheck.length > 0 ? (
  //     //             <button
  //     //               className="filter-btn"
  //     //               onClick={(e) => {
  //     //                 filterByDomains(e);
  //     //               }}
  //     //             >
  //     //               Filter
  //     //             </button>
  //     //           ) : null}
  //     //           {departmentCheck.length > 0 ? (
  //     //             departmentCheck.map((el, i) => {
  //     //               return (
  //     //                 <div className="filterCheckbox">
  //     //                   {" "}
  //     //                   <input
  //     //                     key={i}
  //     //                     defaultChecked={selectedData.department.includes(
  //     //                       el.departmentName
  //     //                     )}
  //     //                     value={el.departmentName}
  //     //                     type="checkbox"
  //     //                     onChange={(e) => {
  //     //                       checkBoxChange(e, "department");
  //     //                     }}
  //     //                   />
  //     //                   <p className="filterName">{el.departmentName}</p>
  //     //                 </div>
  //     //               );
  //     //             })
  //     //           ) : (
  //     //             <p>Select Domains</p>
  //     //           )}
  //     //         </div>
  //     //       )}
  //     //     </div>
  //     //   );
  //     // },
  //   },
  //   {
  //     title: "Coursename",
  //     field: "course",
  //     emptyValue: () => "----",
  //     // filterComponent: () => {
  //     //   return (
  //     //     <div className="FilterByComponent">
  //     //       <div
  //     //         className="FilterBy"
  //     //         onClick={(e) => {
  //     //           handleDropDown(e, "course");
  //     //         }}
  //     //       >
  //     //         <ArrowDropDown />
  //     //       </div>
  //     //       {courseToggle && (
  //     //         <div className="filterCheckboxDropDown">
  //     //           {coursesCheck.length > 0 && (
  //     //             <button
  //     //               className="filter-btn"
  //     //               onClick={(e) => {
  //     //                 filterByDomains(e);
  //     //               }}
  //     //             >
  //     //               Filter
  //     //             </button>
  //     //           )}
  //     //           {coursesCheck.length > 0 ? (
  //     //             coursesCheck.map((el, i) => {
  //     //               return (
  //     //                 <div className="filterCheckbox">
  //     //                   {" "}
  //     //                   <input
  //     //                     key={i}
  //     //                     defaultChecked={selectedData.course.includes(
  //     //                       el.courseName
  //     //                     )}
  //     //                     value={el.courseName}
  //     //                     type="checkbox"
  //     //                     onChange={(e) => {
  //     //                       checkBoxChange(e, "course");
  //     //                     }}
  //     //                   />
  //     //                   <p className="filterName">{el.courseName}</p>
  //     //                 </div>
  //     //               );
  //     //             })
  //     //           ) : (
  //     //             <p>Select Departments</p>
  //     //           )}
  //     //         </div>
  //     //       )}
  //     //     </div>
  //     //   );
  //     // },
  //   },
  //   { title: "utm_medium", field: "tm_medium", emptyValue: () => "----" },
  //   { title: "utm_campaign", field: "utm_campaign", emptyValue: () => "----" },
  //   { title: "utm_source", field: "utm_source", emptyValue: () => "----" },

  //   {
  //     title: "Date",
  //     field: "createdOn",
  //     type: "date",
  //     dateSetting: { locale: "en-GB" },
  //     filtering: true,
  //     customFilterAndSearch: (term, rowData) => {
  //       let splitDate =
  //         rowData.createdOn != undefined
  //           ? new Date(rowData.createdOn).toLocaleString().split(",")[0]
  //           : "";
  //       let termString = term.toLocaleString().split(",")[0];

  //       return splitDate === termString;
  //     },
  //     emptyValue: () => "----",
  //     render: (rowData) => {
  //       let convertedDate =
  //         rowData.createdOn != undefined
  //           ? `${new Date(rowData.createdOn)}`
  //           : "";

  //       return convertedDate;
  //     },
  //   },
  // ];
  useEffect(() => {
    tableRef.current.dataManager.changePageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    getAllDetails();
    FetchData();
  }, []);

  return (
    <div>
      <div>
        <div className="DateFilterBlock">
              <FormControl >
      <InputLabel style={{fontFamily:"poppins",fontWeight:"600",fontSize:"14px"}} id="mutiple-select-label"> Filter</InputLabel>
            <Select
          style={{width:"100px",}}
        labelId="mutiple-select-label"
        value={selected}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
          {/* <div>
            <label>Start Date :</label>
            <input
              name="startDate"
              onChange={(e) => handleDate(e)}
              value={startDate}
              type="date"
            />
          </div>
          <div>
            <label>End Date :</label>{" "}
            <input
              name="endDate"
              disabled={startDate === ""}
              min={startDate}
              onChange={(e) => handleDate(e)}
              value={endDate}
              type="date"
            />
          </div>
          <button name="dateFilter" onClick={(e) => FilterData(e)}>
            Filter
          </button>
          <button
            onClick={() => {
              resetDate();
            }}
          >
            Reset
          </button> */}
          {go && (
            <button
              onClick={() => {
                goBack();
              }}
            >
              Go back
            </button>
          )}
        </div>
        <MaterialTable
          columns={tableColumns}
          tableRef={tableRef}
          isLoading={loader}
          data={data}
          icons={tableIcons}
          title="Teachnook Lead Dashboard"
          localization={{
            toolbar: {
              exportCSVName: "Export as csv",
              exportPDFName: "Export as pdf!!",
            },
          }}
          options={{
            search: false,
            columnsButton: true,
            filtering: true,
            emptyRowsWhenPaging: false,
            pageSize: pageSize,
            pageSizeOptions: [
              5,
              10,
              15,
              20,
              25,
              50,
              100,
              200,
              300,
              500,
              data.length,
            ],
            exportButton: {
              csv: true,
              pdf: true,
            },
            paginationType: "stepped",
          }}

          // onFilterChange = {(a,b)=>{handleFilter(a,b)}}
          // editable={{
          //   onRowAdd: (newData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         setData([...data, newData]);

          //         resolve();
          //       }, 1000);
          //     }),
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         console.log("old:", oldData);
          //         console.log("new:", newData);
          //         const dataUpdate = [...data];
          //         const index = oldData.tableData.id;
          //         dataUpdate[index] = newData;
          //         setData([...dataUpdate]);

          //         resolve();
          //       }, 1000);
          //     }),
          //   onRowDelete: (oldData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         const dataDelete = [...data];
          //         const index = oldData.tableData.id;
          //         dataDelete.splice(index, 1);
          //         setData([...dataDelete]);

          //         resolve();
          //       }, 1000);
          //     }),
          // }}
        />
      </div>
    </div>
  );
}
