import React, { useEffect } from "react";
import "../css/InflucencerForm.css";
import { useSelector } from "react-redux";
import {getAPI} from './api.js';
import decodedToken from './decodedToken';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { Domain } from "../components/ParticularModel";

import { useState } from "react";
import useCounter from "../components/useCounter";
// import { Link } from '@material-ui/core';
import { Link, Redirect, useHistory } from "react-router-dom";
import CouponInfo from "./CouponInfo";

export const InflucencerForm = ({ onClick, type, getInfoCP, appFor }) => {
  const [inputflat, setInputFlat] = useState(false);
  const emailPattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  const numberPattern = new RegExp(/^[0-9]+$/);
  const rangePattern = new RegExp(/\b[1-9]\d{0,9}-\d{1,9}\b/);
  const [inputper, setInputPer] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const commonData = {
    couponCode: "",
    couponPurpose: "",
    couponDescription: "",
    status: "",
    startingDate: "",
    endingDate: "",
    discountPercentage: "",
    maximumDiscount: "",
    range: "",
    flatDiscount: "",
    applicablePlanType: [],
    maximumLimit: "",
    applicableOn: [],
  };
  if (appFor !== undefined && appFor === "email") {
    commonData.applicableFor = { email: [""] };
  } else if (appFor !== undefined && appFor === "particular") {
    commonData.applicableFor = { domain: [], department: [], course: [] };
  }
  if(type==='firstscreen'){
    delete commonData.flatDiscount
    delete commonData.maximumDiscount
    delete commonData.discountPercentage
  }
  const [infoData, setInfodata] = useState(commonData);

  if (type === "firstscreen") {
    infoData.type = "Influencer";
   
    
  } else if (type === "secondscreen") {
    infoData.type = "Global";
  } else {
    infoData.type = "Indivisual";
  }

  const postBodyConstructor = (infoData) => {
    let decoded = decodedToken();
   infoData.name =  decoded.firstName + decoded.lastName;
   infoData.email =  decoded.email;
    let fieldArray = [];
    for (let key in infoData) {
      let field = {};
      if (key === "status") {
        continue;
      }
      if (key === "startingDate" || key === "endingDate") {
        field["fieldName"] = key;
        field["fieldValue"] = infoData[key].split("T")[0];
        fieldArray.push(field);
        continue;
      }
      if (key === "isActive") {
        field["fieldName"] = "status";
        field["fieldValue"] = infoData[key] ? "Active" : "Inactive";
        fieldArray.push(field);

        continue;
      }
      field["fieldName"] = key;
      field["fieldValue"] = infoData[key];
      fieldArray.push(field);
    }
    return fieldArray;
  };

  const getToday = () => {
    let date = new Date().toLocaleDateString();
    let dateArr = date.split("/");
    let today = dateArr[2] + "-" + dateArr[0] + "-" + dateArr[1];
    return today;
  };

  const handleCheckBoxes = (e, a) => {
    if (e.target.checked) {
      infoData[a].push(e.target.value);
      setInfodata({ ...infoData, [a]: [...infoData[a]] });
      return;
    } else {
      if (infoData[a].includes(e.target.value)) {
        let newArr = infoData[a].filter((val) => val !== e.target.value);
        setInfodata({ ...infoData, [a]: newArr });
        return;
      }
      return;
    }
  };

  const handelInflucerSubmit = () => {
    
    if (infoData.isActive === undefined) {
      setError(true);
      return;
    }

    if (!numberPattern.test(infoData.maximumLimit)) {
      setError(true);
      return;
    }

    if (infoData.type!=="Influencer" &&     infoData.discountPercentage === "" &&
      infoData.maximumDiscount === "" &&
      infoData.flatDiscount === ""
    ) {
      setError(true);
      return;
    }
  
    if (infoData.type!=="Influencer" && 
      infoData.flatDiscount !== undefined &&
      !numberPattern.test(infoData.flatDiscount)
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableFor !== undefined &&
      infoData.applicableFor.email !== undefined &&
      infoData.applicableFor.email.length === 0
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableFor !== undefined &&
      infoData.applicableFor.domain !== undefined &&
      (infoData.applicableFor.domain.length === 0 ||
        infoData.applicableFor.department.length === 0 ||
        infoData.applicableFor.course.length === 0)
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableFor !== undefined &&
      infoData.applicableFor.email !== undefined &&
      infoData.applicableFor.email.length > 0 &&
      !infoData.applicableFor.email.every((email) => emailPattern.test(email))
    ) {
      setError(true);
      return;
    }

    if (infoData.type!=='Influencer' &&
      infoData.discountPercentage !== undefined &&
      (!numberPattern.test(infoData.discountPercentage) ||
        !numberPattern.test(infoData.maximumDiscount))
    ) {
      setError(true);
      return;
    }

    if (
      infoData.applicableOn.length === 0 ||
      infoData.applicablePlanType.length === 0
    ) {
      setError(true);
      return;
    }

    if (
      infoData.couponCode === "" ||
      infoData.couponDescription === "" ||
      infoData.couponPurpose === "" ||
      infoData.range === ""
    ) {
      setError(true);
      return;
    }

    if (!rangePattern.test(infoData.range)) {
      setError(true);
      return;
    } else if (rangePattern.test(infoData.range)) {
      let rangeArr = infoData.range.split("-");
      if (parseInt(rangeArr[0]) >= parseInt(rangeArr[1])) {
        setError(true);
        return;
      }
    }

    if (Date.parse(infoData.startingDate) == Date.parse(infoData.endingDate)) {
      alert("Starting Date and Ending Date Should not be Same");
      return;
    }

    if (Date.parse(infoData.startingDate) > Date.parse(infoData.endingDate)) {
      alert("Starting Date should not be greater than Ending Date");
      return;
    }

    if (infoData.startingDate == "" || infoData.endingDate == "") {
      setError(true);
      return;
    }

    submitDetails();
  };

  const submitDetails = () => {
    let details = postBodyConstructor(infoData);
    setError(false);
    setLoading(true);
   const API = getAPI();
    API({
      method: "post",
      url: "coupon/createcoupon",
      data: details,
    })
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            getInfoCP();
            onClick();
          }, 1000);
        }
      })
      .catch((err) => {
        if(err.response && err.response.status===403){
          alert("Your session got Expired. Please login again");
         history.push("/signIn");
        }else{
          alert("Something Went wrong. Please Try again");
          setLoading(false);
        }
      });
  };

  const handelInflucerModel = (e) => {
    if (infoData.discountPercentage || infoData.maximumDiscount) {
      delete infoData.flatDiscount;
    } else if (infoData.flatDiscount) {
      delete infoData.discountPercentage;
      delete infoData.maximumDiscount;
    }
    let value = e.target.value;
    if (e.target.type === "radio") {
      value = e.target.value === "active" ? true : false;
    }
    if (e.target.name === "isActive") {
      setInfodata({
        ...infoData,
        isActive: e.target.value === "active" ? true : false,
      });
    }
    setInfodata({ ...infoData, [e.target.name]: value });
  };


  const changeCount = useCounter();

  const Handleadd = (e) => {
    const values = e.target.checked;
    if (values === true) {
      setInputFlat(true);
      setInputPer(false);
    }
  };

  const Handleadd2 = (e) => {
    if (e.target.value === "v2") {
      setInputPer(true);
      setInputFlat(false);
    }
  };
  const addEmail = () => {
   
    let values = { ...infoData };

    values.applicableFor.email.push("");

    setInfodata({
      ...infoData,
      ["applicableFor"]: {
        ...infoData.applicableFor,
        ["email"]: [...values.applicableFor.email],
      },
    });
  };

  const deleteMail = (index) => {
 
    let values = { ...infoData };
    values.applicableFor.email.splice(index, 1);
    setInfodata({
      ...infoData,
      ["applicableFor"]: {
        ...infoData.applicableFor,
        ["email"]: [...values.applicableFor.email],
      },
    });
  };

  const handelEmail = (e, index) => {
    let newEmailArr = [...infoData.applicableFor.email];
    newEmailArr[index] = e.target.value;
    setInfodata({
      ...infoData,
      ["applicableFor"]: { ...infoData.applicableFor, ["email"]: newEmailArr },
    });
  };

  return (
    <>
      <div className="InflucencerForm">
        <div className="InfluceForm">
          <div className="Info-div">
            <button className="btn" onClick={onClick}>
              ✖
            </button>

            {infoData.applicableFor &&
              infoData.applicableFor.email !== undefined && (
                <>
                  <div className="emailLabelBlock">
                    {" "}
                    <label>Email</label>
                    <button className="btnemail" onClick={addEmail}>
                      Add Email
                    </button>
                  </div>
                  {infoData.applicableFor.email.map((ele, index) => {
                    return (
                      <div className="emailBar" key={index}>
                        <input
                          className="inputdata"
                          placeholder="Enter Email"
                          type="text"
                          name={`email${index}`}
                          value={ele}
                          onChange={(e) => handelEmail(e, index)}
                        />
                        <DeleteForeverIcon
                          style={{ padding: 0, marginLeft: 10 }}
                          onClick={() => deleteMail(index)}
                        />
                        {error &&
                        !emailPattern.test(
                          infoData.applicableFor.email[index]
                        ) ? (
                          <p style={{ color: "red", fontSize: "initial" }}>
                            Invalid Email
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                  {error && infoData.applicableFor.email.length === 0 ? (
                    <p style={{ color: "red", fontSize: "initial" }}>
                      Please give atleast one Email
                    </p>
                  ) : null}
                </>
              )}

            <label>Coupon Code</label>
            <input
              type="text"
              name="couponCode"
              placeholder="Enter Coupon Code"
              value={infoData.couponCode}
              onChange={handelInflucerModel}
            />
            {error && infoData.couponCode === "" ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Please fill the above field
              </p>
            ) : null}
            <label>Coupon Purpose</label>
            <input
              type="text"
              className="coupenPurpose"
              placeholder="Enter Coupon Purpose"
              name="couponPurpose"
              value={infoData.couponPurpose}
              onChange={handelInflucerModel}
            />
            {error && infoData.couponPurpose === "" ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Please fill the above field
              </p>
            ) : null}
            <label>Coupon Descriptions</label>
            <textarea
              className="coupon-des"
              name="couponDescription"
              value={infoData.couponDescription}
              onChange={handelInflucerModel}
            ></textarea>
            {error && infoData.couponDescription === "" ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Please fill the above field
              </p>
            ) : null}

            <div className="status" onChange={handelInflucerModel}>
              <label>status</label>
              <div className="col-flex">
                <input
                  type="radio"
                  id="a1"
                  name="isActive"
                  value="active"
                  onChange={handelInflucerModel}
                />
                <label className="distance">Active</label>
              </div>
              <div className="col-flex">
                <input
                  type="radio"
                  id="a2"
                  name="isActive"
                  value="notActive"
                  onChange={handelInflucerModel}
                />
                <label className="distance">Inactive</label>
              </div>
            </div>
            {error && infoData.isActive === undefined ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Please Select the Status
              </p>
            ) : null}

            <label> Starting Date </label>
            <input
              type="date"
              name="startingDate"
              min={getToday()}
              value={infoData.startingDate.split("T")[0]}
              onChange={handelInflucerModel}
            />
            <label> Ending Date </label>
            <input
              type="date"
              name="endingDate"
              min={infoData.startingDate.split("T")[0]}
              value={infoData.endingDate.split("T")[0]}
              onChange={handelInflucerModel}
            />
            {error &&
            (infoData.startingDate === "" || infoData.endingDate === "") ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Please Select the Dates
              </p>
            ) : null}

           { infoData.type!=='Influencer' ?<div className="discount">
              <label> Discount type </label>
              <div className="col-flex">
                <input
                  onClick={Handleadd}
                  value="v1"
                  type="radio"
                  id="a1"
                  name="discountType"
                />
                <label className="distance">Flat Discount</label>

                {inputflat && (
                  <input
                    type="text"
                    name="flatDiscount"
                    style={{ marginLeft: "80px" }}
                    placeholder="Enter In Rupees"
                    value={infoData.flatDiscount}
                    onChange={handelInflucerModel}
                  />
                )}
              </div>
              {error &&
              infoData.discountPercentage === undefined &&
              infoData.flatDiscount !== undefined &&
              !numberPattern.test(infoData.flatDiscount) ? (
                <p style={{ color: "red", fontSize: "initial" }}>
                  Invalid Discount
                </p>
              ) : null}

              <div className="col-flex">
                <input
                  onClick={Handleadd2}
                  value="v2"
                  id="a2"
                  name="discountType"
                  type="radio"
                />
                <label className="distance">Discount Percentage</label>

                {inputper && (
                  <div className="input-two">
                    <input
                      type="text"
                      placeholder="%"
                      name="discountPercentage"
                      style={{ marginLeft: "34px" }}
                      value={infoData.discountPercentage}
                      onChange={handelInflucerModel}
                    />
                    <input
                      type="text"
                      placeholder="maximum Discount"
                      name="maximumDiscount"
                      style={{ marginLeft: "10px" }}
                      value={infoData.maximumDiscount}
                      onChange={handelInflucerModel}
                    />
                  </div>
                )}
              </div>
              {error &&
              infoData.discountPercentage !== undefined &&
              infoData.flatDiscount === undefined &&
              (!numberPattern.test(infoData.flatDiscount) ||
                !numberPattern.test(infoData.flatDiscount)) ? (
                <p style={{ color: "red", fontSize: "initial" }}>
                  Invalid Discount
                </p>
              ) : null}
              {error &&
              infoData.discountPercentage === "" &&
              infoData.maximumDiscount === "" &&
              infoData.flatDiscount === "" ? (
                <p style={{ color: "red", fontSize: "initial" }}>
                  Please Select the Discount Type
                </p>
              ) : null}
            </div>:null}

            <div className="applicable">
              <label> Applicable On </label>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicableOn"
                  value="website"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicableOn.includes("website")}
                />
                <label className="distance">Website</label>
              </div>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicableOn"
                  value="link"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicableOn.includes("link")}
                />{" "}
                <label className="distance">Link</label>
              </div>
              {error && infoData.applicableOn.length === 0 ? (
                <p style={{ color: "red", fontSize: "initial" }}>
                  Select atleast on of the checkboxes
                </p>
              ) : null}
            </div>

            {infoData.applicableFor && infoData.applicableFor.domain && (
              <Domain
                applyFor={(e) => {
               
                  setInfodata({ ...infoData, ["applicableFor"]: e });
                }}
              />
            )}
            {error &&
            infoData.applicableFor &&
            infoData.applicableFor.domain &&
            (infoData.applicableFor.domain.length === 0 ||
              infoData.applicableFor.department.length === 0 ||
              infoData.applicableFor.course.length === 0) ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Please Select the Courses
              </p>
            ) : null}

            <label>Coupon Limits</label>
            <div className="coupanlimit">
              <div className="datavalue">
                <input
                  name="maximumLimit"
                  value={infoData.maximumLimit}
                  onChange={handelInflucerModel}
                />
              </div>
            </div>
            {error && !numberPattern.test(infoData.maximumLimit) ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Invalid Copoun Limit
              </p>
            ) : null}

            <label>Money Range</label>

            <div className="moneyrange">
              <div>
                <p>Staring Range - Ending Range</p>
                <input
                  type="text"
                  name="range"
                  value={infoData.range}
                  onChange={handelInflucerModel}
                />
              </div>
            </div>
            {error && !rangePattern.test(infoData.range) ? (
              <p style={{ color: "red", fontSize: "initial" }}>Invalid Range</p>
            ) : null}
            {error &&
            rangePattern.test(infoData.range) &&
            parseInt(infoData.range.split("-")[0]) >=
              parseInt(infoData.range.split("-")[1]) ? (
              <p style={{ color: "red", fontSize: "initial" }}>
                Starting range must be less than Ending Range
              </p>
            ) : null}

            <div className="plantype">
              <label>Plan Type</label>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicablePlanType"
                  value="selfPaced"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicablePlanType.includes(
                    "selfPaced"
                  )}
                />
                <label className="distance">Self Paced</label>
              </div>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicablePlanType"
                  value="mentorLed"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicablePlanType.includes(
                    "mentorLed"
                  )}
                />
                <label className="distance">Mentor Led</label>
              </div>
              <div className="col-flex">
                <input
                  type="checkbox"
                  name="applicablePlanType"
                  value="advanced"
                  onChange={(e) => {
                    handleCheckBoxes(e, e.target.name);
                  }}
                  defaultChecked={infoData.applicablePlanType.includes(
                    "advanced"
                  )}
                />
                <label className="distance">Advanced</label>
              </div>
              {!(
                infoData.applicableFor !== undefined &&
                infoData.applicableFor.domain !== undefined
              ) ? (
                <>
                  <div className="col-flex">
                    <input
                      type="checkbox"
                      name="applicablePlanType"
                      value="silver"
                      onChange={(e) => {
                        handleCheckBoxes(e, e.target.name);
                      }}
                      defaultChecked={infoData.applicablePlanType.includes(
                        "silver"
                      )}
                    />
                    <label className="distance">Silver</label>
                  </div>
                  <div className="col-flex">
                    <input
                      type="checkbox"
                      name="applicablePlanType"
                      value="gold"
                      onChange={(e) => {
                        handleCheckBoxes(e, e.target.name);
                      }}
                      defaultChecked={infoData.applicablePlanType.includes(
                        "gold"
                      )}
                    />
                    <label className="distance">Gold</label>
                  </div>
                  <div className="col-flex">
                    <input
                      type="checkbox"
                      name="applicablePlanType"
                      value="diamond"
                      onChange={(e) => {
                        handleCheckBoxes(e, e.target.name);
                      }}
                      defaultChecked={infoData.applicablePlanType.includes(
                        "diamond"
                      )}
                    />
                    <label className="distance">Diamond</label>
                  </div>
                </>
              ) : null}

              {error && infoData.applicablePlanType.length === 0 ? (
                <p style={{ color: "red", fontSize: "initial" }}>
                  Select atleast one plan type
                </p>
              ) : null}
            </div>
            {error && (
              <p
                className="detailsError"
                style={{ fontSize: "initial", color: "red" }}
              >
                Please fill the details correctly
              </p>
            )}

            <button className="create" onClick={handelInflucerSubmit}>
              {loading ? <p>Creating...</p> : <p>Create</p>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
