
import axios from "axios";
import React, { useEffect, useState, useRef,useCallback } from "react";
import '../css/AddCertificates.css'
import { getAPI  } from "../components/api";
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Delete from "@material-ui/icons/Delete";
import Carousel, { Modal, ModalGateway } from "react-images";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: "75%",
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    position: "absolute",
    top: "8%",
    borderRadius:20,
    backgroundColor: 'rgb(162 166 227)',
    padding:'2% 1%',
    boxShadow:" 0px 0px 10px 10px #03125173"
  },
  gridList: {
    width: "100%",
    height: 355,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
   
  },
}));


function AddCertificates() {
  const formRef = useRef(" ");
  const inputRef = useRef();
  const classes = useStyles();
  const form = formRef.current;
  const [currentImage,setCurrentImage] = useState(0);
  const [viewerIsOpen,setViewerIsOpen] = useState(false);
    const [image, setImage] = useState([]);
  const [responseMessage,setResponseMessage]=useState("Try again later")
  const [show,setShow]=useState(false)
  const [showPreview,setShowPreview]=useState(false)
  const [originalscreen,setoriginalScreen]=useState(false)
  
  function imageHandler(event) {
    const tempArr = [];
    [...event.target.files].map((file) => {
      tempArr.push(file);
    });
    setImage([...tempArr]);
  }
// let output

 function handlePreviewEvent(){
  if(image.length===0){
    alert("Select Certificates before submitting")
    return
  }else{

    setShowPreview(true)    
      }
    }

  function handleClickEvent(e,data) {
    if(data==="preview"){
      setoriginalScreen(true)
    }
    if(image.length===0){
      alert("Select Certificates before preview")
      return
    }
    
    const formData = new FormData();
    for (let i = 0; i <= image.length - 1; i++) {
      formData.append("CERT", image[i]);
    }
    
    const API  = getAPI()
    API({
      method: "post",
      url: 'certificates',
      data: formData
    })
      .then((response) => {
        if(response){
          setShow(true)
          alert(response.data);
          setShowPreview(false);
          const dt = new DataTransfer();
          inputRef.current.files=dt.files;
     
         
         
        }
      })
      .catch((err) => {
        setShow(true)
      });
  }
 function handleDelete(imageDeleted){
   let newImgArr = image.filter(ce=>ce.name!==imageDeleted);
   
  
  const dt = new DataTransfer()
 
  const { files } = inputRef.current
  
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    if ( file.name!== imageDeleted)
      dt.items.add(file) 
  }
  
  inputRef.current.files = dt.files 

  setImage(newImgArr);
 

 }

 const handleClose = ()=>{
   setShowPreview(false);
 }

 const openLightbox = useCallback((event, index) => {
  setCurrentImage(index);
 
  setViewerIsOpen(true);
}, []);

const closeLightbox = () => {
  setCurrentImage(0);
  setViewerIsOpen(false);
};

  return (<>{originalscreen==true||originalscreen==false?
    <div className = "certicateSection">
        <h1>Upload Your Certificates</h1>
    
      <form id="form" ref={formRef} encType="multipart/form">
        <input
         className = "custom-file-input"
         id="file"
          type="file"
          name="CERT"
          ref  = {inputRef}
          onChange={(event) => {
            imageHandler(event);
          }}
          multiple
        />
        <p
          className="submit-certifcate-btn"
         
        
          onClick={() => {
            handleClickEvent();
          }}
        >
          Submit
        </p>
        <p
          className="submit-certifcate-btn"
         
        
          onClick={() => {
            handlePreviewEvent();
          }}
        >
          Preview
        </p>
      </form>
      {showPreview===true &&image.length>0?
      
      <div className={classes.root}>
        <div className = "closeDiv"><Close className = 'closeIcon' onClick ={()=>handleClose()}></Close></div>
      <GridList cellHeight={300}  spacing={30} className={classes.gridList + ' '+'scrollbar'}>
        {/* <GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
          <ListSubheader  component="div"></ListSubheader>
        </GridListTile> */}
        {image.map((file,key) => (
          <GridListTile key={key} style = {{width:"33%" ,height:290}} >
            <img className = "certificateImg" src={URL.createObjectURL(file)} alt={file.name} onClick={(e)=>openLightbox(e,key)}  />
            <GridListTileBar
              title={file.name}
              actionIcon={
                <IconButton className={classes.icon}>
                  <Delete className= 'deleteIcon' onClick ={()=>handleDelete(file.name)} />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={image.map(x => ({
               caption:x.name,
               alt:x.name,
               source:URL.createObjectURL(x)
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <div className = "submitDiv"><button className ="submit-certifcate-btn submit-preview"  onClick={(e) => {
            handleClickEvent(e,'preview');
          }}>Submit</button></div>
    </div>:""}
    
    </div>:""}
 </> );
}

export default AddCertificates;
