import React, { useEffect, useState } from "react";
import Table from "../components/Table";

const WebLeadPage = () => {
  // const[loader , setLoader] = useState(true)

  // useEffect(() => {
  //     setTimeout(() => {
  //        setLoader(false)
  //     } , 2000)

  // })

  return (
    <div style={{ overflow: "auto", width: "83%",marginLeft:"17%"}}>
      <Table />
    </div>
  );
};

export default WebLeadPage;
