import React, { useState } from "react";
import "../css/loginregister.css";
import Bgimg from "../images/bgimg.svg";
import '../css/Navbar.css'
import axios from 'axios';
import {useDispatch} from 'react-redux';
import decodedToken from '../components/decodedToken'
import {useHistory} from 'react-router-dom';
import {loginAction} from '../reducer'
const emailPattern = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const ForgotPassword=()=>{
  const [error , setError] = useState(false)
  const dispatch = useDispatch();
const history = useHistory()
 const [user , setUser]  = useState ({
      email : ""
 }) ;
 const [errMsg,setErrMsg]=useState("")
 const handleChange = (e) => {
  const value = e.target.value
   setUser({...user , 
    [e.target.name] : value ,
})
setError(false)

}
const handleSubmit =  (e)=>{
  e.preventDefault();
  if(!emailPattern.test(user.email)){
    setErrMsg("Invalid Email ")
    setError(true);
    return
  }
  submitDetails(); 
}
const submitDetails = ()=>{
  setError(false)

  axios({
   method: "post",
   url: `https://vforbackend.verzeo.com/forgotPassword`,
   data: user,
 })
   .then((res) => {
     if (res.status === 200) { 
    // setErrMsg(res.data)
    // setError(true)
    //  history.push("/signIn");
    setErrMsg(res.data)
    setError(true)
    setTimeout(()=>{
        history.push("/signIn");
    },1000)
     
     }
     else if (res.status === 202) {    
      //  dispatch(loginAction(decodeToken.tabs));
      setErrMsg("Email not exist")
      setError(true)
      //  history.push("/signIn");
       }
   })
   .catch((err) => {
  
   });
}
return(
  <>
  <div className="login-register">
  <div className="login-inner">
    <div className="login-form">

      <form method = "POST" className="input-field">
      <h1 style={{textAlign: 'center',color: "#071e40", fontSize: "30px"}}>Forgot-Password</h1>
    <div> <input  type="text" placeholder="Email" className="inputtext" name = "email"  value= {user.email} onChange={handleChange} />
    </div>
    {error?<p  style={{color : "red"}}>{errMsg}</p>:null}
     <button type="submit" onClick={handleSubmit} className="login-btn">Send link</button>
     </form>

    </div>
    <div className="login-img">
      <img  src={Bgimg} style={{width: '69%'}}/>
    </div>
    


  </div> 


  </div>
  </>
)
}
export default ForgotPassword